import Constant from '@/const';
import axios from 'axios';

const getCategoryByRT = async () => {
  try {
    const response = await axios.get(
      Constant.apiUrl.concat('/master/category/menu/Rumah Tangga'),
    );
    return response.data.data;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default getCategoryByRT;
