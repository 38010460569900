<template>
  <v-card class="px-5">
    <v-card-title>
      <v-layout>
        <v-flex xs2>
          <v-layout>
            <v-flex><p class="title-head">No.RM</p></v-flex>
            <v-flex
              ><p class="data-head">{{ patientData.rmNumber }}</p></v-flex
            >
          </v-layout>
        </v-flex>
        <v-flex xs3>
          <v-layout>
            <v-flex><p class="title-head">Nama</p></v-flex>
            <v-flex
              ><p class="data-head">
                {{ patientData.meta.name }} ({{
                  patientData.meta.gender === 'Perempuan' ? 'P' : 'L'
                }})
              </p></v-flex
            >
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <v-layout>
            <v-flex><p class="title-head">Tanggal Lahir</p></v-flex>
            <v-flex
              ><p class="data-head">
                {{ patientData.meta.birthDate }} ({{ patientData.meta.age }}
                tahun)
              </p></v-flex
            >
          </v-layout>
        </v-flex>
        <v-flex xs4>
          <h3 style="text-align: right">Tindakan & BHP</h3>
        </v-flex>
        <v-flex xs1 class="text-end">
          <v-icon @click="close()">mdi-close</v-icon>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text class="row-scroll">
      <v-layout class="form-body" pl-8 pt-4>
        <v-flex xs6>
          <v-form ref="action">
            <v-layout justify-start>
              <v-flex xs4 pt-2 class="text-left">
                <label class="form-body__title required">Tindakan</label>
              </v-flex>
              <v-flex xs5>
                <v-autocomplete
                  placeholder="Pilih Tindakan"
                  class="pt-0"
                  dense
                  :items="service"
                  item-text="name"
                  :rules="rules.action"
                  item-value="_id"
                  :loading="loading"
                  return-object
                  v-model="inputService.serviceName"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs4 pt-4 class="text-left">
                <label class="form-body__title required">Jumlah</label>
              </v-flex>
              <v-flex xs5>
                <v-text-field
                  class="pt-0 mt-0"
                  :rules="rules.actionAmount"
                  v-model.number="inputService.amount"
                  type="number"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs4 pt-4 class="text-left">
                <label class="form-body__title">Total Biaya Tindakan</label>
              </v-flex>
              <v-flex xs4 pt-4>
                <p
                  class="text-left black--text"
                  style="font-size: 14px"
                  v-if="!inputService.amount"
                >
                  Rp 0
                </p>
                <p class="text-left black--text" style="font-size: 14px" v-else>
                  Rp
                  {{
                    formatNumber(
                      (inputService.total =
                        inputService.amount * inputService.serviceName.total),
                    )
                  }}
                </p>
              </v-flex>
            </v-layout>
            <v-layout justify-start>
              <v-flex xs4 pt-2 class="text-left">
                <label class="form-body__title required">Nama Operator</label>
              </v-flex>
              <v-flex xs5>
                <v-autocomplete
                  placeholder="Pilih Nama Operator"
                  :rules="rules.operator"
                  class="pt-0 mt-0"
                  dense
                  auto-select-first
                  :items="staff"
                  item-text="name"
                  :loading="loading"
                  v-model="inputService.operator"
                  return-object
                ></v-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs4></v-flex>
              <v-flex xs4>
                <v-btn
                  tile
                  color="primary"
                  elevation="0"
                  width="160"
                  @click="addService()"
                  >Tambahkan</v-btn
                >
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
        <v-flex xs6>
          <v-form ref="consumableform">
            <v-layout>
              <v-flex xs4 pt-2 class="text-left">
                <label class="form-body__title required">BHP/Alkes</label>
              </v-flex>
              <v-flex xs4>
                <v-autocomplete
                  class="pt-0 mt-0"
                  dense
                  :items="bhp"
                  :rules="rules.consumable"
                  item-text="name"
                  :loading="loading"
                  v-model="inputConsumable.consumableName"
                  return-object
                  placeholder="Pilih BHP/Alkes"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs4 pt-4 class="text-left">
                <label class="form-body__title required">Jumlah</label>
              </v-flex>
              <v-flex xs4>
                <v-layout>
                  <v-flex
                    ><v-text-field
                      class="pt-0"
                      :rules="rules.consumableAmount"
                      v-model="inputConsumable.amount"
                      type="number"
                    ></v-text-field
                  ></v-flex>
                  <v-flex class="text-left"
                    ><p class="pt-4 pl-2">
                      {{ inputConsumable.consumableName.packaging }}
                    </p></v-flex
                  >
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs4 pt-3 class="text-left">
                <label class="form-body__title">Total Biaya BHP</label>
              </v-flex>
              <v-flex xs4>
                <p class="text-left pt-3" v-if="inputConsumable.amount === ''">
                  Rp 0
                </p>
                <p class="text-left pt-3" v-if="inputConsumable.amount !== ''">
                  Rp
                  {{
                    formatNumber(
                      (inputConsumable.total =
                        inputConsumable.amount *
                        inputConsumable.consumableName.hpp),
                    )
                  }}
                </p>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs4></v-flex>
              <v-flex xs4>
                <v-btn
                  tile
                  color="primary"
                  elevation="0"
                  width="160"
                  @click="addBHP()"
                  >Tambahkan</v-btn
                >
              </v-flex>
            </v-layout>
          </v-form>
        </v-flex>
      </v-layout>
      <v-layout justify-center pt-5>
        <v-data-table
          :headers="consumable.headers"
          :items="consumable.item"
          fixed-header
          style="width: 1000px"
          :items-per-page="5"
          :loading="loading"
        >
          <template v-slot:[`item`]="{ item }">
            <tr>
              <td class="text-left">{{ item.displayDate }}</td>
              <td class="text-left">{{ item.displayTime }}</td>
              <td class="text-left">{{ item.name }}</td>
              <td>{{ item.quantity }}</td>
              <td class="text-end">Rp {{ formatNumber(item.total_price) }}</td>
              <td class="text-left">{{ item.staff }}</td>
              <td>
                <v-btn icon v-if="item.isNew" @click="deleteItem(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <!-- <template v-slot:[`item.price`]="{ item }">
            <td class="text-right">Rp {{ formatNumber(item.price) }}</td>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <v-btn icon v-if="item.isNew" @click="deleteItem(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template> -->
        </v-data-table>
      </v-layout>
      <v-layout pt-5>
        <v-flex xs7>
          <h1 class="black--text">Total Biaya</h1>
        </v-flex>
        <v-flex class="text-left">
          <h1 class="black--text">Rp {{ formatNumber(totalCost) }}</h1>
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        tile
        primary
        class="blue--text"
        width="152"
        @click="addData()"
        >Simpan</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
import alertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const moment = require('moment-timezone');
const _ = require('lodash');

export default {
  name: 'BHPandAction',
  mixins: [jwtMixin, alertMixin],
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    id_registration: {
      type: String,
    },
  },
  data() {
    return {
      staff: [],
      service: [],
      loading: false,
      bhp: [],
      serviceCost: '',
      total: 0,
      totalService: 0,
      inputService: {
        serviceName: '',
        amount: null,
        total: '',
        operator: '',
      },
      inputConsumable: {
        consumableName: '',
        amount: '',
        total: '',
        operator: '',
      },
      input: [
        {
          id_goods: '',
          packaging: '',
          quantity: '',
          price: '',
        },
      ],
      totalCost: 0,
      consumable: {
        headers: [
          { text: 'Tanggal', value: 'displayDate' },
          { text: '', value: 'displayTime' },
          { text: 'Nama Tindakan/BHP', value: 'name' },
          {
            text: 'Jumlah',
            value: 'quantity',
            class: 'text-center',
            sortable: false,
          },
          { text: 'Subtotal Biaya', value: 'total_price', sortable: false },
          { text: 'Operator', value: 'staff' },
          { text: '', value: 'delete' },
        ],
        item: [],
      },
      rules: {
        action: [v => !!v || 'Masukkan Tindakan'],
        actionAmount: [
          v => !!v || 'Masukkan Jumlah',
          v => v > 0 || 'Jumlah Harus Lebih dari 0',
        ],
        consumableAmount: [
          v => !!v || 'Masukkan Jumlah',
          v => v > 0 || 'Jumlah Harus Lebih dari 0',
        ],
        consumable: [v => !!v || 'Masukkan BHP/Alkes'],
        operator: [v => !!v || 'Masukkan Operator'],
      },
    };
  },
  mounted() {
    this.$nextTick(function() {
      this.inputService.operator = {
        id: this.$store.getters.userLoggedIn.id,
        name: this.$store.getters.userLoggedIn.name,
      };
      this.getStaff();
      this.getService();
      this.getConsumable();
      this.showAllConsumable();
    });
  },
  methods: {
    addData() {
      const payload = this.consumable.item.filter(v => v.isNew === true);

      axios
        .post(
          Constant.apiUrl.concat(
            `/patient/intercons/${this.patientData.id_registration}`,
          ),
          {
            item: payload,
            created_by: this.$store.getters.userLoggedIn.id,
          },
        )
        .then(response => {
          if (response.status === 200) {
            Swal.fire('Data Tindakan berhasil disimpan!', '', 'success');
            this.close();
            this.showAllConsumable();
            this.consumable.item = '';
          }
        })
        .catch(err => {
          this.showErrorAxios(err);
        });
    },
    close() {
      this.$emit('close-form');
    },
    getConsumable() {
      this.loading = true;
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              `/master/service/consumable/${this.patientData.id_registration}`,
            ),
          )
          .then(response => {
            let data = response.data.data;
            this.bhp = data.map(bhp => {
              return {
                id: {
                  id_drugs: bhp.id_drugs,
                  id_goods: bhp.id_goods,
                },
                name: bhp.name,
                hpp: bhp.hpp,
                packaging: bhp.packaging,
              };
            });
            this.loading = false;
          });
      });
    },
    getStaff() {
      this.loading = true;
      axios.get(Constant.apiUrl.concat('/master/staff')).then(response => {
        let data = response.data.data;
        this.staff = data
          .filter(x => x.role.status)
          .map(staff => {
            return {
              id: staff._id.toString(),
              name: staff.detail.name,
            };
          });
        this.loading = false;
      });
    },
    getService() {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/service?page=1&itemCount=&sort=&search=&category=${this.patientData.meta.guarantorType.toLowerCase()}`,
          ),
        )
        .then(response => {
          let data = response.data.data;
          this.service = data.map(service => {
            return {
              _id: service._id.toString(),
              name: service.name,
              total: _.get(service, 'cost.total', 0),
            };
          });
          this.loading = false;
        });
    },
    addService() {
      if (this.$refs.action.validate()) {
        var arrayPreInput = {};
        arrayPreInput = {
          date: moment().format(),
          displayDate: moment().format('DD/MM/YYYY'),
          displayTime: moment().format('HH:mm'),
          id_service: this.inputService.serviceName._id,
          name: this.inputService.serviceName.name,
          quantity: this.inputService.amount,
          price: this.inputService.serviceName.total,
          total_price: this.inputService.total,
          staff: this.inputService.operator.name,
          id_staff: this.inputService.operator.id,
          isNew: true,
        };
        this.consumable.item.push(arrayPreInput);
        this.totalCost = 0;
        for (let i = 0; i < this.consumable.item.length; i++) {
          this.totalCost = this.totalCost + this.consumable.item[i].total_price;
        }

        this.inputService = {
          serviceName: '',
          amount: '',
          total: '',
          operator: {
            id: this.$store.getters.userLoggedIn.id,
            name: this.$store.getters.userLoggedIn.name,
          },
        };
        this.$refs.action.resetValidation();
      }
    },
    addBHP() {
      if (this.$refs.consumableform.validate()) {
        var arrayPreInput = {};
        if (this.inputConsumable.consumableName.id.id_goods) {
          arrayPreInput = {
            date: moment().format(),
            displayDate: moment().format('DD/MM/YYYY'),
            displayTime: moment().format('HH:mm'),
            id_goods: this.inputConsumable.consumableName.id.id_goods,
            name: this.inputConsumable.consumableName.name,
            quantity: +this.inputConsumable.amount,
            price: this.inputConsumable.consumableName.hpp,
            total_price: this.inputConsumable.total,
            staff: this.$store.getters.userLoggedIn.name,
            id_staff: this.$store.getters.userLoggedIn.id,
            isNew: true,
          };
          this.consumable.item.push(arrayPreInput);
          this.totalCost = 0;
          for (let i = 0; i < this.consumable.item.length; i++) {
            this.totalCost =
              this.totalCost + this.consumable.item[i].total_price;
          }
        } else {
          arrayPreInput = {
            date: moment().format(),
            displayDate: moment().format('DD/MM/YYYY'),
            displayTime: moment().format('HH:mm'),
            id_drugs: this.inputConsumable.consumableName.id.id_drugs,
            name: this.inputConsumable.consumableName.name,
            quantity: +this.inputConsumable.amount,
            price: this.inputConsumable.consumableName.hpp,
            total_price: this.inputConsumable.total,
            staff: this.$store.getters.userLoggedIn.name,
            id_staff: this.$store.getters.userLoggedIn.id,
            isNew: true,
          };
          this.consumable.item.push(arrayPreInput);
          this.totalCost = 0;
          for (let i = 0; i < this.consumable.item.length; i++) {
            this.totalCost =
              this.totalCost + this.consumable.item[i].total_price;
          }
        }
        this.inputConsumable = {
          consumableName: '',
          amount: '',
          total: '',
          operator: '',
        };
        this.$refs.consumableform.resetValidation();
      }
    },
    showAllConsumable() {
      this.loading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/patient/intercons/${this.patientData.id_registration}`,
          ),
        )
        .then(response => {
          const data = response.data.data;
          this.consumable.item = data.map(consume => {
            return {
              ...consume,
              date: consume.date,
              displayDate:
                moment(consume.date).format('DD/MM/YYYY') == 'Invalid date'
                  ? moment(consume.date, 'DD/MM/YYYY').format('DD/MM/YYYY')
                  : moment(consume.date).format('DD/MM/YYYY'),
              displayTime:
                moment(consume.date).format('DD/MM/YYYY') == 'Invalid date'
                  ? moment(consume.date, 'DD/MM/YYYY').format('HH:mm')
                  : moment(consume.date).format('HH:mm'),
              price: consume.price,
              staff: consume.staff,
              isNew: false,
            };
          });
          this.totalCost = this.consumable.item.reduce(
            (a, { total_price }) => a + total_price,
            0,
          );
          this.loading = false;
        });
    },
    deleteItem(item) {
      const index = this.consumable.item.indexOf(item);
      this.consumable.item.splice(index, 1);
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    },
  },
};
</script>

<style lang="scss" scoped>
.title-head {
  font-size: 14px;
}

.data-head {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}
.head-table {
  border-top: 1px solid #f4f5f5;
  border-bottom: 1px solid #f4f5f5;
  text-align: left;
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }
}

.body-table {
  &__data {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
  }
}

.form-body {
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: black;
    text-align: left;
  }
}
.card-table {
  height: 200px;
  overflow-y: scroll;
}
.row-scroll {
  overflow-y: scroll;
  height: 440px;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}

.required:after {
  content: ' *';
  color: red;
}
</style>
