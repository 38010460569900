<template>
  <v-card class="d-flex dental-main">
    <history-sidebar v-if="!isEmr && !isIhs" />
    <v-container fluid>
      <header-assessment
        :data="headerData"
        pl="2vw"
        pr="2vw"
        mb="2vw"
        title="Assesmen Poli Gigi"
      />
      <v-main v-if="!isLoading">
        <!-- EMR -->
        <input-assessment
          v-if="!emrData"
          v-on="$listeners"
          @close-form="$emit('close-form')"
          :patient-data="patient"
          :is-ihs="isIhs"
          :tab="tabs"
        />

        <!-- EMR -->
        <input-assessment
          v-else
          v-on="$listeners"
          @close-form="$emit('close-form')"
          :patient-data="patient"
          :emr-data="emrData"
          :is-ihs="isIhs"
          :is-emr="isEmr"
        />
      </v-main>
    </v-container>
  </v-card>
</template>

<script>
// import HistoryAssessment from './HistoryAssessment.vue';
import HistorySidebar from '../AssessmentComponents/HistorySidebar.vue';
import InputAssessment from './InputAssessment';
import HeaderAssessment from '../AssessmentComponents/HeaderAssessment.vue';
import formatMixin from '@/mixin/formatMixin';

const moment = require('moment');

// api
import { getSocialDataByRmNumber } from '@/fetchApi/patient';

export default {
  name: 'DentalPolyAssestment',
  mixins: [formatMixin],
  components: { InputAssessment, HistorySidebar, HeaderAssessment },
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    idRegistration: {
      type: String,
      default: null,
    },
    rmNumber: {
      type: String,
      default: null,
    },
    isIhs: {
      type: Boolean,
      default: null,
    },
    isEmr: Boolean,
    emrData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      headerData: null,
      patient: null,
      isLoading: true,
    };
  },
  mounted() {
    this.getSocialData();
  },
  methods: {
    async getSocialData() {
      try {
        this.isLoading = true;
        const response = await getSocialDataByRmNumber(
          this.patientData?.rmNumber || this.rmNumber,
        );
        this.assignHeaderData(response.data.data);
        this.assignPatientDataForProps(response.data.data);
      } catch {
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
    assignHeaderData(data) {
      this.headerData = [
        {
          label: 'No.RM',
          text: this.patientData?.rmNumber || this.rmNumber,
        },
        {
          label: 'Nama',
          text: `${data.name} (${data.gender ? 'L' : 'P'})`,
        },
        {
          label: 'Tanggal Lahir',
          text: `${moment(data.birth.date, 'YYYY-MM-DD').format(
            'DD/MM/YYYY',
          )} (${data.age} Tahun)`,
        },
        {
          label: 'Hambatan dalam Berkomunikasi',
          text: data.commmunication_obstacles || 'Tidak Ada',
        },
      ];
    },
    assignPatientDataForProps(data) {
      this.patient = {
        rmNumber: this.patientData?.rmNumber || this.rmNumber,
        meta: {
          name: data.name,
          address: this.formatAddress(data.address),
          poly: this.patientData.meta.poly,
          birthDate: moment(data.birth.date, 'YYYY-MM-DD').format(
            'DD MMMM YYYY',
          ),
          age: data.age,
          gender: data.gender,
          guarantorType: data.assurance.type,
          guarantorNumber: data.assurance.number,
          // address: data.address,
          // poly: 'Poli Gigi',
        },
        // rmNumber: this.patientData.rmNumber,
        id_registration:
          this.patientData?.id_registration || this.idRegistration,
        id_emr: data.id_emr,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.dental-main {
  font-family: 'Roboto';
  height: 100%;
}
</style>
