import EventBus from '@/eventBus';
import bus from '@/eventBus/bus.js';

export default {
  mixins: [bus],
  created() {
    this.busListener();
  },
  methods: {
    emitMessageCall(payload) {
      this.$socket.client.emit('message:call', payload);
      // EventBus.$emit('message:call', payload);
    },
    async onMessageUpdate({ refresh, isCalling }) {
      this.$socket.client.on('message:update', async data => {
        await refresh(); // refresh data on page
        if (isCalling && data.queue_code)
          this.callPatient({
            ...data,
            patient_name: data.patient_name.toLowerCase(),
          });
        // EventBus.$emit('message-update', { refresh, isCalling, data });
      });
    },
    emitIsCalling(data) {
      EventBus.$emit('message:call', data);
    },
    async checkSound() {
      this.$socket.client.on('message:check-sound-call', () => {
        const audio = this.$refs.notification;
        if (audio.paused) {
          this.$socket.client.emit('message:check-sound-on');
          audio.play();
          this.$socket.client.emit('message:check-sound-end');
        } else {
          audio.pause();
          audio.currentTime = 0;
        }
      });
    },
    callPatient(data) {
      const methods = this;
      const statusCall = status => {
        const trimmedStatus = status.trim().toUpperCase();
        const statusMapping = {
          'TN.': 'Tuan',
          'NN.': 'Nona',
          'NY.': 'Nyonya',
          'BY.': 'Bayi',
          'AN.': 'Anak',
          'SDR.': 'Saudara',
        };
        return statusMapping[trimmedStatus] || '';
      };

      methods.speak('Nomor antrean', 1);

      const alphanumeric = data.queue_code
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '')
        .split('');
      for (let i = 0; i < alphanumeric.length; i++) {
        methods.speak(alphanumeric[i], 1);
      }

      methods.speak('Atas Nama', 1);

      methods.speak(
        `${statusCall(
          data.patient_name.split(' ')[data.patient_name.split(' ').length - 1],
        )} ${data.patient_name.split(' ').slice(0, -1)}`,
        1,
      );

      methods.speak(`Silahkan ke ruangan ${data.room}`, 1);
    },
    speak(word, rate) {
      // const methods = this;

      const speech = new SpeechSynthesisUtterance(word);
      speech.rate = rate;
      speech.lang = 'id-ID';
      window.speechSynthesis.speak(speech);

      // if (type === 'start') {
      //   speech.onstart = function() {
      //     methods.emitIsCalling(true);
      //   };
      // }
      // if (type === 'end') {
      //   speech.onend = function() {
      //     methods.emitIsCalling(false);
      //   };
      // }
    },
  },
};
