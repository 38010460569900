<template>
  <v-card>
    <v-container fluid>
      <div class="sticky">
        <div class="d-flex justify-space-between align-center px-4">
          <h2>Permintaan Obat dan Alkes</h2>
          <v-btn text fab @click="$emit('close-form')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row class="px-4">
          <v-col class="grid-meta">
            <span><skeleton :isLoading="loading">No. RM</skeleton></span>

            <span
              ><skeleton :isLoading="loading">{{
                patientData.rmNumber
              }}</skeleton></span
            >

            <span><skeleton :isLoading="loading">Tanggal Lahir</skeleton></span>
            <span
              ><skeleton :isLoading="loading"
                >{{ patientData.birthDate }} ({{
                  patientData.age
                }}
                Tahun)</skeleton
              >
            </span>

            <span><skeleton :isLoading="loading">Tipe Penjamin</skeleton></span>

            <span
              ><skeleton :isLoading="loading">{{
                patientData.assurance
              }}</skeleton>
            </span>
          </v-col>
          <v-col class="grid-meta">
            <span><skeleton :isLoading="loading">Nama</skeleton></span>
            <span
              ><skeleton :isLoading="loading"
                >{{ patientData.name }} ({{ patientData.gender }})</skeleton
              ></span
            ><span><skeleton :isLoading="loading">Unit</skeleton></span>
            <span
              ><skeleton :isLoading="loading">{{
                patientData.unit
              }}</skeleton></span
            >
            <span><skeleton :isLoading="loading">DPJP</skeleton></span
            ><span
              ><skeleton :isLoading="loading">{{
                patient.doctor
              }}</skeleton></span
            ></v-col
          ><v-col class="text-start">
            <span class="font-weight-bold">
              <skeleton :isLoading="loading">Riwayat Alergi</skeleton>
            </span>
            <v-col class="text-start px-0">
              <span
                v-if="!patientData?.allergy?.length"
                class="red--text font-weight-bold"
                ><skeleton :isLoading="loading"
                  >Tidak Ada Alergi</skeleton
                ></span
              >
              <skeleton :isLoading="loading">
                <v-chip
                  v-for="(allergy, i) in patientData.allergy"
                  :key="i"
                  color="#EB4747"
                  class="allergy text-capitalize mr-2 mb-2"
                  label
                  small
                  caption
                  >{{ allergy }}</v-chip
                ></skeleton
              >
            </v-col>
          </v-col>
        </v-row>
        <v-tabs v-model="menuSelected" centered grow>
          <v-tab>
            <label class="text-none font-weight-bold"> Permintaan Obat </label>
          </v-tab>
          <v-tab>
            <label class="text-none font-weight-bold">
              Permintaan Alkes
            </label>
          </v-tab>
          <v-tab>
            <label class="text-none font-weight-bold">
              Histori Permintaan dan Pemberian Obat
            </label>
          </v-tab>
        </v-tabs>
      </div>
      <div class="scrollable-area">
        <v-tabs-items v-model="menuSelected">
          <v-tab-item>
            <drug-request v-if="recipeData.id" />
          </v-tab-item>
          <v-tab-item>
            <v-row no-gutters>
              <medtool-request />
            </v-row>
          </v-tab-item>

          <v-tab-item>
            <v-row no-gutters style="height: 65vh">
              <drug-history />
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import DrugRequest from './component/DrugRequest';
import DrugHistory from './component/DrugHistory';
import MedtoolRequest from './component/MedtoolRequest';
import Skeleton from '../../../SharedComponent/Skeleton.vue';
import alertMixin from '@/mixin/alertMixin';

const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);

export default {
  components: {
    DrugRequest,
    DrugHistory,
    MedtoolRequest,
    Skeleton,
  },
  props: {
    patient: {
      type: Object,
      default: null,
    },
  },
  mixins: [alertMixin],
  data() {
    return {
      expanded: false,
      recipeReviewed: false,
      recipe: {},
      data: [],
      drug: [],
      dialog: false,
    };
  },
  computed: {
    ...mapGetters([
      'getMenuSelected',
      'getPatientData',
      'getLoading',
      'getRecipe',
    ]),
    menuSelected: {
      get() {
        return this.getMenuSelected;
      },
      set(value) {
        this.setMenuSelected(value);
      },
    },
    recipeData: {
      get() {
        return this.getRecipe;
      },
    },
    patientData: {
      get() {
        return this.getPatientData;
      },
    },
    loading: {
      get() {
        return this.getLoading.pharmacy;
      },
    },
  },
  beforeDestroy() {
    // Clear all state in store modules
    this.clearState('recipe');
    this.clearState('patient');
    this.clearState('price');
    this.clearState('history');
    this.clearState('review');
    this.clearState('medtool');
    this.clearState('menu');
  },
  mounted() {
    this.$nextTick(() => {
      this.getPatientPharmacyData();
    });
  },
  methods: {
    ...mapActions(['resolveGetPharmacyPatientData']),
    ...mapMutations(['clearState', 'setMenuSelected']),
    async getPatientPharmacyData() {
      try {
        await this.resolveGetPharmacyPatientData(this.patient.id);
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    closeDialogForm() {
      this.$emit('close-form');
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky {
  width: 100%;
  background-color: #ffffff !important;

  label {
    font-size: 1vw;
    letter-spacing: 0.001vw;
  }

  .grid-meta {
    display: grid;
    grid-template-columns: 40% 60%;
    text-align: start;
    gap: 0.3vw;

    span {
      font-size: 0.85vw;
      font-weight: 400;
    }
  }

  .allergy {
    background-color: #fff2f2 !important;
    font-weight: 400;
    font-size: 13px;
    justify-content: center;
    border: 0.5px solid #eb4747 !important;
    color: #eb4747;
  }
}

.scrollable-area {
  height: 75vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}
</style>
