import moment from 'moment-timezone';
import JsPDF from 'jspdf';
import Constant from '../const';
import checkboxTrue from '@/assets/image/checkboxTrue.png';
import checkboxFalse from '@/assets/image/checkboxFalse.png';
import checked from '@/assets/image/checked.png';
import printMixin from './printMixin';
import 'jspdf-autotable';
import utils from './utils';

export default {
  methods: {
    generateGeneralConsentDoc(data, socialData) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const leftMargin = 1.0;
      const tab = 0.5;
      const justifyWidth = 5.4;
      const indexingSpace = 0.15;
      const bottomMargin = 10.7;
      let haveAddPage = false;

      doc.setProperties({
        title: `General Consent no.RM: ${data.rmNumber}`,
      });

      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      this.pdfTextPrint(doc, 'PERSETUJUAN UMUM', 0.7);
      this.pdfTextPrint(doc, '(GENERAL CONSENT)', 1);
      doc.setFontSize(10);
      doc.setFont('Helvetica', 'bold');
      this.pdfTextPrint(doc, 'IDENTITAS PASIEN', 1.4, 'left', leftMargin);
      doc.setFont('Helvetica', 'normal');
      doc.autoTable({
        body: [
          ['Nama', ':', data.signer === 'wali' ? socialData.name : data.name],
          ['Nomor RM', ':', data.rmNumber],
          ['Tanggal Lahir', ':', data.birthDate],
          [
            'Alamat',
            ':',
            data.signer === 'wali' ? socialData.address : data.address,
          ],
          [
            'No. Telp',
            ':',
            data.signer === 'wali' ? socialData.telp : data.telp,
          ],
        ],
        startY: 1.5,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 1.75 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 4 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });
      let lastLine = doc.autoTable.previous.finalY;

      this.pdfTextPrint(
        doc,
        'Yang bertanda tangan dibawah ini :',
        lastLine + 0.2,
        'left',
        leftMargin,
      );

      doc.autoTable({
        body: [
          ['Nama', ':', data.name],
          ['Alamat', ':', data.address],
          ['No. Telp', ':', data.telp],
          ['No. Identitas/ KTP/SIM', ':', data.identityNumber],
        ],
        startY: lastLine + 0.3,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 1.75 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 4 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });
      lastLine = doc.autoTable.previous.finalY;

      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      this.pdfTextPrint(
        doc,
        `Selaku hukum di ${this.$store.getters.userLoggedIn.clinic.name}, dengan ini menyatakan persetujuan :`,
        0.1 + lastLine,
        'left',
        leftMargin,
      );
      this.pdfTextPrint(
        doc,
        '1. PERSETUJUAN UNTUK PERAWATAN DAN PENGOBATAN',
        0.3 + lastLine,
        'left',
        leftMargin + 0.1,
      );
      this.pdfTextPrint(doc, 'a.', 0.6 + lastLine, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.a1,
        leftMargin + tab + indexingSpace,
        0.6 + lastLine,
        {
          maxWidth: justifyWidth,
          align: 'justify',
        },
      );
      doc.internal.write(0, 'Tw');
      this.pdfTextPrint(doc, 'b.', 1.45 + lastLine, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.b1,
        leftMargin + tab + indexingSpace,
        1.45 + lastLine,
        {
          maxWidth: justifyWidth,
          align: 'justify',
        },
      );
      doc.internal.write(0, 'Tw');
      this.pdfTextPrint(doc, 'c.', 1.77 + lastLine, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.c1,
        leftMargin + tab + indexingSpace,
        1.77 + lastLine,
        {
          maxWidth: justifyWidth,
          align: 'justify',
        },
      );
      doc.internal.write(0, 'Tw');

      this.pdfTextPrint(
        doc,
        '2. PERSETUJUAN PELEPASAN INFORMASI',
        2.3 + lastLine,
        'left',
        leftMargin + 0.1,
      );
      this.pdfTextPrint(doc, 'a.', 2.65 + lastLine, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.a2,
        leftMargin + tab + indexingSpace,
        2.65 + lastLine,
        {
          maxWidth: justifyWidth,
          align: 'justify',
        },
      );
      doc.internal.write(0, 'Tw');
      this.pdfTextPrint(doc, 'b.', 3.2 + lastLine, 'left', leftMargin + tab);
      doc.text(
        `${this.resource.pointToPrint.b2} ${
          data.visitorAllowed.filter(i => i.name && i.relation).length < 1
            ? '-'
            : ''
        }`,
        leftMargin + tab + indexingSpace,
        3.2 + lastLine,
        {
          maxWidth: justifyWidth,
          align: 'justify',
        },
      );
      doc.internal.write(0, 'Tw');

      let lastHeightAdjust = 3.7 + lastLine;
      if (data.restrictedInfo) {
        data.visitorAllowed.forEach((content, index) => {
          if (lastHeightAdjust > bottomMargin) {
            doc.addPage();
            haveAddPage = true;
            lastHeightAdjust = 1.0;
          }
          if (content.name !== '') {
            this.pdfTextPrint(
              doc,
              `${index + 1}. ${content.name}`,
              lastHeightAdjust,
              'left',
              leftMargin + tab + indexingSpace,
            );
            lastHeightAdjust += 0.15;
          }
        });
      }
      lastHeightAdjust += 0.15;
      this.pdfTextPrint(doc, 'c.', lastHeightAdjust, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.c2,
        leftMargin + tab + indexingSpace,
        lastHeightAdjust,
        { maxWidth: justifyWidth, align: 'justify' },
      );
      doc.internal.write(0, 'Tw');
      if (haveAddPage) {
        lastHeightAdjust += 0.8;
        haveAddPage = false;
      }
      if (lastHeightAdjust > 8.0) {
        doc.addPage();
        lastHeightAdjust = 1.0;
      }
      this.pdfTextPrint(
        doc,
        '3. HAK DAN KEWAJIBAN PASIEN',
        lastHeightAdjust,
        'left',
        leftMargin,
      );
      lastHeightAdjust += 0.3;
      this.pdfTextPrint(doc, 'a.', lastHeightAdjust, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.a3,
        leftMargin + tab + indexingSpace,
        lastHeightAdjust,
        { maxWidth: justifyWidth, align: 'justify' },
      );
      doc.internal.write(0, 'Tw');
      lastHeightAdjust += 0.4;
      this.pdfTextPrint(doc, 'b.', lastHeightAdjust, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.b3,
        leftMargin + tab + indexingSpace,
        lastHeightAdjust,
        { maxWidth: justifyWidth, align: 'justify' },
      );
      doc.internal.write(0, 'Tw');
      lastHeightAdjust += 0.4;
      this.pdfTextPrint(doc, 'c.', lastHeightAdjust, 'left', leftMargin + tab);
      doc.text(
        this.resource.pointToPrint.c3,
        leftMargin + tab + indexingSpace,
        lastHeightAdjust,
        { maxWidth: justifyWidth, align: 'justify' },
      );
      doc.internal.write(0, 'Tw');
      lastHeightAdjust += 0.6;
      doc.internal.write(0, 'Tw');
      lastHeightAdjust += 1;

      const createdAt = `${moment(data.timestamps.created_at)
        .locale('id')
        .format('DD MMMM YYYY')}`;
      this.pdfTextPrint(
        doc,
        `${this.$store.getters.userLoggedIn.clinic.clinicRegion}, ${createdAt}`,
        lastHeightAdjust,
        'halfCenterStart',
        // leftMargin,
      );
      lastHeightAdjust += 0.25;

      this.pdfTextPrint(doc, `Dokter`, lastHeightAdjust, 'halfCenterStart');
      this.pdfTextPrint(doc, `Pasien/ Wali`, lastHeightAdjust, 'halfCenterEnd');

      lastHeightAdjust += 1.0;
      this.pdfTextPrint(
        doc,
        data.staff.text,
        lastHeightAdjust,
        'halfCenterStart',
      );
      this.pdfTextPrint(doc, data.name, lastHeightAdjust, 'halfCenterEnd');

      return doc;
    },

    generateInformedConsentDoc(data, patient) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const leftMargin = 1.0;
      const justifyWidth = 6.1;

      doc.setProperties({
        title: `Informed Consent no.RM: ${data.rmNumber}`,
      });
      doc.setFontSize(10);
      this.pdfTextPrint(
        doc,
        'Yang bertanda tangan dibawah ini :',
        1.6,
        'left',
        leftMargin,
      );
      doc.autoTable({
        body: [
          ['Nama', ':', data.signerName],
          ['Umur', ':', data.signer !== 'wali' ? data.age : data.waliAge],
          ['Alamat', ':', data.address],
          ['No. Telp/HP', ':', data.phoneNumber],
        ],
        startY: 1.7,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 1.75 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 3 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });
      let lastLine = doc.autoTable.previous.finalY;

      this.pdfTextPrint(doc, data.text[0], lastLine + 0.4, 'left', leftMargin);
      doc.autoTable({
        body: [
          ['Nama', ':', patient.meta.name],
          ['No.Kartu ASKES/BPJS', ':', data.bpjsNumber],
          ['Umur', ':', patient.meta.age],
          ['Alamat', ':', patient.meta.address],
        ],
        startY: lastLine + 0.5,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 1.75 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 3 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });
      lastLine = doc.autoTable.previous.finalY;

      doc.text(data.text[1], leftMargin, lastLine + 0.5, {
        maxWidth: justifyWidth,
        align: 'justify',
        lineHeightFactor: 1.5,
      });
      let statementLineEnter = lastLine + 0.9;
      data.text.slice(2).forEach((item, index) => {
        let lastLineText;
        if (!index) {
          lastLineText = statementLineEnter;
        } else {
          statementLineEnter += 0.5;
          lastLineText = statementLineEnter;
        }
        doc.text(item, leftMargin, lastLineText, {
          maxWidth: justifyWidth,
          align: 'justify',
          lineHeightFactor: 1.5,
        });
      });

      this.pdfTextPrint(
        doc,
        'Dokter',
        statementLineEnter + 2.5,
        'halfCenterStart',
      );
      this.pdfTextPrint(
        doc,
        `${this.$store.getters.userLoggedIn.clinic.clinicRegion}, ${moment()
          .locale('id')
          .format('DD MMMM YYYY')}`,
        statementLineEnter + 2.1,
        'halfCenterEnd',
      );
      this.pdfTextPrint(
        doc,
        `Yang Menyatakan,`,
        statementLineEnter + 2.3,
        'halfCenterEnd',
      );
      this.pdfTextPrint(
        doc,
        `Pasien/Keluarga`,
        statementLineEnter + 2.5,
        'halfCenterEnd',
      );
      this.pdfTextPrint(
        doc,
        data.doctor.text,
        statementLineEnter + 3.5,
        'halfCenterStart',
      );
      this.pdfTextPrint(
        doc,
        data.signerName,
        statementLineEnter + 3.5,
        'halfCenterEnd',
      );
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(12);
      this.pdfTextPrint(doc, 'INFORMED CONSENT', 0.9);
      this.pdfTextPrint(doc, '(FORM PERSETUJUAN TINDAKAN MEDIS)', 1.15);

      return doc;
    },

    generateInformedConsentDocV2(data, patient) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });
      // const doc = new JsPDF('p', 'pt');

      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;
      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      const leftMargin = 1.0;

      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(12);
      this.pdfTextPrint(doc, 'INFORMED CONSENT', 0.9);
      this.pdfTextPrint(doc, '(FORM PERSETUJUAN TINDAKAN MEDIS)', 1.15);
      doc.setFont('Helvetica', 'normal');

      doc.setProperties({
        title: `Informed Consent no.RM: ${patient.noRM}`,
      });
      doc.setFontSize(10);
      this.pdfTextPrint(
        doc,
        'Saya yang bertanda tangan di bawah ini: ',
        1.6,
        'left',
        leftMargin,
      );

      doc.autoTable({
        body: [
          ['Nama', ':', data.signer_name],
          [
            'Umur',
            ':',
            `${data.signer !== 'wali' ? patient.age : data.signer_age} Tahun`,
          ],
          ['Alamat', ':', data.address],
        ],
        startY: 1.7,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 0.6, cellPadding: 0.03 },
          1: { cellWidth: 0.18, cellPadding: 0.03 },
          2: { cellWidth: 5.2, cellPadding: 0.03 },
        },
        margin: {
          left: leftMargin - 0.04,
          right: 4,
        },
        theme: 'plain',
      });
      let lastLine = doc.autoTable.previous.finalY;

      doc.autoTable({
        body: [
          [
            'Menyatakan dengan sesungguhnya selaku ',
            {
              content: `${
                data.signer === 'pasien' ? 'pasien' : data.signer_as
              } dari:`,
              styles: { fontStyle: 'bold' },
            },
          ],
        ],
        startY: lastLine + 0.3,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 2.7, cellPadding: 0.01 },
          1: { cellWidth: 4, cellPadding: 0.01 },
        },
        margin: {
          left: leftMargin - 0.04,
          right: 4,
        },
        theme: 'plain',
      });
      doc.autoTable({
        body: [
          ['Nama', ':', patient.name],
          ['Umur', ':', `${patient.age} Tahun`],
          ['Alamat', ':', data.address],
        ],
        startY: lastLine + 0.6,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 0.6, cellPadding: 0.03 },
          1: { cellWidth: 0.18, cellPadding: 0.03 },
          2: { cellWidth: 5.2, cellPadding: 0.03 },
        },
        margin: {
          left: leftMargin - 0.04,
          right: 4,
        },
        theme: 'plain',
      });
      utils.methods.printBoldCharacters(
        doc,
        `Dengan ini menyatakan ${this.generateAgreement(
          data.patient_action_agreement,
        )} dilakukan tindakan medis berupa ${
          data.information_checklist.action.value.id === 'etc'
            ? data.information_checklist.action.etc
            : data.information_checklist.action.value.name
        }. Saya telah memahami penjelasan perihal jenis, manfaat, dan risiko tindakan medis tersebut. Yang tujuan, sifat, dan perlunya tindakan medis tersebut di atas serta risiko yang dapat ditimbulkan telah dijelaskan oleh petugas yang bersangkutan dan saya mengerti sepenuhnya.`,
        lastLine + 1.6,
        leftMargin,
        6.4,
      );
      let statementLineEnter = lastLine + 0.9;

      alignCenter('Saksi', undefined, statementLineEnter + 2.1, -2);
      alignCenter('(_____________)', undefined, statementLineEnter + 2.7, -2);

      alignCenter('Petugas', undefined, statementLineEnter + 2.1, 0);
      alignCenter(
        data.staff_executor.name,
        undefined,
        statementLineEnter + 2.7,
        0,
      );

      alignCenter(
        `${this.$store.getters.userLoggedIn.clinic.clinicRegion}, ${moment(
          this.timestamps,
        )
          .locale('id')
          .format('DD MMMM YYYY')}`,
        undefined,
        statementLineEnter + 1.7,
        2.25,
      );
      alignCenter(
        `${data.signer === 'pasien' ? 'Pasien' : data.signer_as}`,
        undefined,
        statementLineEnter + 2.1,
        2,
      );
      alignCenter(data.signer_name, undefined, statementLineEnter + 2.7, 2);
      return doc;
    },

    generateFormInformedConsent(data, patient) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };
      const leftMargin = 0.4;
      // letter head
      printMixin.methods.letterHead(
        doc,
        this.$store.getters.userLoggedIn.clinic,
      );

      doc.setProperties({
        title: `Informed Consent no.RM: ${patient.noRM}`,
      });
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(11);
      alignCenter('PERSETUJUAN TINDAKAN MEDIS', undefined, addLine(3.8));
      doc.setFont('Helvetica', 'bolditalic');
      alignCenter('(INFORMED CONSENT)', undefined, addLine(4.7));
      doc.setFont('Helvetica', 'normal');
      const {
        action,
        action_indication,
        action_risk,
        alt_risk,
        base_diagnose,
        complication,
        cost,
        diagnose,
        others,
        procedure,
        prognosis,
        purpose,
      } = data.information_checklist;

      doc.autoTable({
        body: [
          [
            {
              content: 'IDENTITAS PASIEN',
              styles: { fontStyle: 'bold', halign: 'center', valign: 'middle' },
              colSpan: 3,
            },
          ],
          [
            'No RM',
            {
              content: patient.noRM,
              colSpan: 2,
            },
          ],
          [
            'Nama',
            {
              content: patient.name,
              colSpan: 2,
            },
          ],
          [
            'Tempat & Tanggal Lahir',
            {
              content: patient.birthPlace + ', ' + patient.birthDate,
              colSpan: 2,
            },
          ],
          [
            'Alamat',
            {
              content:
                data.address.length > 75
                  ? data.address.substring(0, 75) + '...'
                  : data.address,
              colSpan: 2,
            },
          ],
          [
            {
              content: 'PEMBERIAN INFORMASI',
              styles: { fontStyle: 'bold', halign: 'center', valign: 'middle' },
              colSpan: 3,
            },
          ],
          [
            'Dokter Penanggungjawab',
            {
              content: data.doctor.name,
              colSpan: 2,
            },
          ],
          [
            'Pelaksana Tindakan',
            {
              content: data.staff_executor.name,
              colSpan: 2,
            },
          ],
          [
            'Pemberi Informasi',
            {
              content: data.staff_information.name,
              colSpan: 2,
            },
          ],
          [
            'Penerima Informasi',
            {
              content: data.patient_receiver_info,
              colSpan: 2,
            },
          ],
          [
            {
              content: 'JENIS INFORMASI',
              styles: { fontStyle: 'bold', halign: 'center', valign: 'middle' },
            },
            {
              content: 'ISI INFORMASI',
              styles: { fontStyle: 'bold', halign: 'center', valign: 'middle' },
            },
            {
              content: 'Tanda (     )',
              styles: { fontStyle: 'bold', halign: 'center', valign: 'middle' },
            },
          ],
          // ['Diagnosis', diagnose.value[0].name, ''],
          [
            'Diagnosis',
            diagnose.value
              .map(item => {
                return item.name;
              })
              .join(', ').length >= 70
              ? diagnose.value
                  .map(item => {
                    return item.name;
                  })
                  .join(', ')
                  .slice(0, 70) + '...'
              : diagnose.value
                  .map(item => {
                    return item.name;
                  })
                  .join(', '),
            '',
          ],
          ['Dasar Diagnosis', base_diagnose.value, ''],
          [
            'Tindakan Kedokteran',
            action.value.id === 'etc' ? action.etc : action.value.name,
            '',
          ],
          ['Indikasi Tindakan', action_indication.value, ''],
          ['Risiko Tindakan', action_risk.value, ''],
          ['Tata Cara', procedure.value, ''],
          ['Tujuan', purpose.value, ''],
          ['Komplikasi', complication.value, ''],
          ['Prognosis', prognosis.value, ''],
          ['Alternatif dan Risiko', alt_risk.value, ''],
          ['Biaya', cost.value, ''],
          ['Lain-Lain', others.value, ''],
          [
            {
              content:
                'Dengan ini menyatakan bahwa saya telah menerangkan hal-hal di atas secara lancar dan jujur serta memberikan kesempatan untuk bertanya dan/atau berdiskusi',
              colSpan: 2,
            },
            '',
          ],
          [
            {
              content:
                'Dengan ini menyatakan bahwa saya telah menerima informasi sebagaimana di atas, yang saya beri tanda (     ) atau paraf pada kolom kanannya dan telah memahaminya',
              colSpan: 2,
            },
            '',
          ],
          [
            {
              content:
                '(*) Bila pasien tidak berkompeten atau tidak mau menerima informasi, maka penerima informasi adalah wali/keluarga terdekat',
              colSpan: 3,
            },
          ],
        ],
        startY: addLine(5),
        styles: {
          lineColor: [0, 0, 0],
          lineWidth: 0.01,
          fillColor: false,
          textColor: [0, 0, 0],
          font: 'helvetica',
          fontSize: 9,
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        columnStyles: {
          0: { cellPadding: 0.04, cellWidth: 2 },
          1: { cellPadding: 0.04, cellWidth: 4.5 },
          2: { cellPadding: 0.04, cellWidth: 1 },
        },
        theme: 'grid',
      });
      // Check Image
      doc.addImage(checked, 'PNG', 7.535, 4.4, 0.13, 0.12);
      doc.addImage(checked, 'PNG', 6.42, 7.65, 0.13, 0.12);
      // diagnose Check Image
      diagnose.isCheck && doc.addImage(checked, 'PNG', 7.35, 4.62, 0.15, 0.14);
      // base_diagnose Check Image
      base_diagnose.isCheck &&
        doc.addImage(checked, 'PNG', 7.35, 4.84, 0.15, 0.14);
      // action Check Image
      action.isCheck && doc.addImage(checked, 'PNG', 7.35, 5.06, 0.15, 0.14);
      // action_indication Check Image
      action_indication.isCheck &&
        doc.addImage(checked, 'PNG', 7.35, 5.28, 0.15, 0.14);
      // action_risk Check Image
      action_risk.isCheck &&
        doc.addImage(checked, 'PNG', 7.35, 5.5, 0.15, 0.14);
      // procedure Check Image
      procedure.isCheck && doc.addImage(checked, 'PNG', 7.35, 5.72, 0.15, 0.14);
      // purpose Check Image
      purpose.isCheck && doc.addImage(checked, 'PNG', 7.35, 5.94, 0.15, 0.14);
      // complication Check Image
      complication.isCheck &&
        doc.addImage(checked, 'PNG', 7.35, 6.18, 0.15, 0.14);
      // prognosis Check Image
      prognosis.isCheck && doc.addImage(checked, 'PNG', 7.35, 6.4, 0.15, 0.14);
      // alt_risk Check Image
      alt_risk.isCheck && doc.addImage(checked, 'PNG', 7.35, 6.62, 0.15, 0.14);
      // cost Check Image
      cost.isCheck && doc.addImage(checked, 'PNG', 7.35, 6.84, 0.15, 0.14);
      // others Check Image
      others.isCheck && doc.addImage(checked, 'PNG', 7.35, 7.06, 0.15, 0.14);

      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(10);
      alignCenter('TINDAKAN MEDIS', undefined, addLine(33.3));
      doc.setFont('Helvetica', 'normal');

      doc.setFontSize(9);
      this.pdfTextPrint(
        doc,
        'Saya yang bertanda tangan di bawah ini: ',
        addLine(34),
        'left',
        leftMargin,
      );

      doc.autoTable({
        body: [
          ['Nama', ':', data.signer_name],
          [
            'Umur',
            ':',
            `${data.signer !== 'wali' ? patient.age : data.signer_age} Tahun`,
          ],
          [
            'Alamat',
            ':',
            data.address.length > 75
              ? data.address.substring(0, 75) + '...'
              : data.address,
          ],
        ],
        startY: addLine(34.2),
        styles: {
          textColor: 0,
          halign: 'left',
          fontSize: 9,
        },
        columnStyles: {
          0: { cellWidth: 0.6, cellPadding: 0.015 },
          1: { cellWidth: 0.18, cellPadding: 0.015 },
          2: { cellWidth: 6.5, cellPadding: 0.015 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });
      let lastLine = doc.autoTable.previous.finalY;

      doc.autoTable({
        body: [
          [
            'Menyatakan dengan sesungguhnya selaku ',
            {
              content: `${
                data.signer === 'pasien' ? 'pasien' : data.signer_as
              } dari:`,
              styles: { fontStyle: 'bold' },
            },
          ],
        ],
        startY: lastLine + 0.01,
        styles: {
          textColor: 0,
          halign: 'left',
          fontSize: 9,
        },
        columnStyles: {
          0: { cellWidth: 2.4, cellPadding: 0.015 },
          1: { cellWidth: 4, cellPadding: 0.015 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });
      doc.autoTable({
        body: [
          ['Nama', ':', patient.name],
          ['Umur', ':', `${patient.age} Tahun`],
          [
            'Alamat',
            ':',
            data.address.length > 75
              ? data.address.substring(0, 75) + '...'
              : data.address,
          ],
        ],
        startY: lastLine + 0.2,
        styles: {
          textColor: 0,
          halign: 'left',
          fontSize: 9,
        },
        columnStyles: {
          0: { cellWidth: 0.6, cellPadding: 0.015 },
          1: { cellWidth: 0.18, cellPadding: 0.015 },
          2: { cellWidth: 6.5, cellPadding: 0.015 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });

      utils.methods.printBoldCharacters(
        doc.setFontSize(9),
        `Dengan ini menyatakan ${this.generateAgreement(
          data.patient_action_agreement,
        )} dilakukan tindakan medis berupa ${
          data.information_checklist.action.value.id === 'etc'
            ? data.information_checklist.action.etc
            : data.information_checklist.action.value.name
        }. Saya telah memahami penjelasan perihal jenis, manfaat, dan risiko tindakan medis tersebut. Yang tujuan, sifat, dan perlunya tindakan medis tersebut di atas serta risiko yang dapat ditimbulkan telah dijelaskan oleh petugas yang bersangkutan dan saya mengerti sepenuhnya.`,
        lastLine + 0.85,
        leftMargin,
        7.8,
      );

      let statementLineEnter = lastLine + 0.9;

      alignCenter('Saksi', undefined, statementLineEnter + 0.8, -3);
      alignCenter('(_____________)', undefined, statementLineEnter + 1.4, -3);

      alignCenter('Petugas', undefined, statementLineEnter + 0.8, 0);
      alignCenter(
        data.staff_executor.name,
        undefined,
        statementLineEnter + 1.4,
        0,
      );

      alignCenter(
        `${this.$store.getters.userLoggedIn.clinic.clinicRegion}, ${moment(
          this.timestamps,
        )
          .locale('id')
          .format('DD MMMM YYYY')}`,
        undefined,
        statementLineEnter + 0.5,
        3,
      );
      alignCenter(
        `${data.signer === 'pasien' ? 'Pasien' : data.signer_as}`,
        undefined,
        statementLineEnter + 0.8,
        3,
      );
      alignCenter(data.signer_name, undefined, statementLineEnter + 1.4, 3);
      return doc;
    },

    generateEmptyInformedConsentDocV2(patient) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });
      // const doc = new JsPDF('p', 'pt');

      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;
      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      const leftMargin = 1.0;

      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(12);
      this.pdfTextPrint(doc, 'INFORMED CONSENT', 0.9);
      this.pdfTextPrint(doc, '(FORM PERSETUJUAN TINDAKAN MEDIS)', 1.15);
      doc.setFont('Helvetica', 'normal');

      doc.setProperties({
        title: `Informed Consent no.RM: ${patient.noRM}`,
      });
      doc.setFontSize(10);
      this.pdfTextPrint(
        doc,
        'Saya yang bertanda tangan di bawah ini: ',
        1.6,
        'left',
        leftMargin,
      );

      doc.autoTable({
        body: [
          ['Nama', ':', ''],
          ['Umur', ':', ''],
          ['Alamat', ':', ''],
        ],
        startY: 1.7,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 0.6, cellPadding: 0.03 },
          1: { cellWidth: 0.18, cellPadding: 0.03 },
          2: { cellWidth: 5.2, cellPadding: 0.03 },
        },
        margin: {
          left: leftMargin - 0.04,
          right: 4,
        },
        theme: 'plain',
      });
      let lastLine = doc.autoTable.previous.finalY;

      doc.autoTable({
        body: [
          [
            'Menyatakan dengan sesungguhnya selaku ',
            {
              content: `${'__________'} dari:`,
            },
          ],
        ],
        startY: lastLine + 0.3,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 2.7, cellPadding: 0.01 },
          1: { cellWidth: 4, cellPadding: 0.01 },
        },
        margin: {
          left: leftMargin - 0.04,
          right: 4,
        },
        theme: 'plain',
      });
      doc.autoTable({
        body: [
          ['Nama', ':', patient.name],
          ['Umur', ':', `${patient.age} Tahun`],
          ['Alamat', ':', patient.address],
        ],
        startY: lastLine + 0.6,
        style: {
          textColor: 0,
          halign: 'left',
        },
        columnStyles: {
          0: { cellWidth: 0.6, cellPadding: 0.03 },
          1: { cellWidth: 0.18, cellPadding: 0.03 },
          2: { cellWidth: 5.2, cellPadding: 0.03 },
        },
        margin: {
          left: leftMargin - 0.04,
          right: 4,
        },
        theme: 'plain',
      });
      utils.methods.printBoldCharacters(
        doc,
        `Dengan ini menyatakan ${this.generateAgreement(
          'Setuju/Tidak Setuju',
        )} dilakukan tindakan medis berupa ${'_____________'}. Saya telah memahami penjelasan perihal jenis, manfaat, dan risiko tindakan medis tersebut. Yang tujuan, sifat, dan perlunya tindakan medis tersebut di atas serta risiko yang dapat ditimbulkan telah dijelaskan oleh petugas yang bersangkutan dan saya mengerti sepenuhnya.`,
        lastLine + 1.6,
        leftMargin,
        6.7,
      );
      let statementLineEnter = lastLine + 0.9;

      alignCenter('Saksi', undefined, statementLineEnter + 2.1, -2);
      alignCenter(
        '(__________________)',
        undefined,
        statementLineEnter + 2.7,
        -2,
      );

      alignCenter('Petugas', undefined, statementLineEnter + 2.1, 0);
      alignCenter(
        '(__________________)',
        undefined,
        statementLineEnter + 2.7,
        0,
      );

      alignCenter(
        `${
          this.$store.getters.userLoggedIn.clinic.clinicRegion
        }, _______________ ${moment().format('YYYY')}`,
        undefined,
        statementLineEnter + 1.7,
        2.25,
      );
      alignCenter('_____________', undefined, statementLineEnter + 2.1, 2);
      alignCenter(
        '(__________________)',
        undefined,
        statementLineEnter + 2.7,
        2,
      );
      return doc;
    },

    generateEmptyFormInformedConsent(patient) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });

      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;

      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };
      const leftMargin = 0.4;
      // letter head
      printMixin.methods.letterHead(
        doc,
        this.$store.getters.userLoggedIn.clinic,
      );

      doc.setProperties({
        title: `Informed Consent no.RM: ${patient.noRM}`,
      });
      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(11);
      alignCenter('PERSETUJUAN TINDAKAN MEDIS', undefined, addLine(3.8));
      doc.setFont('Helvetica', 'bolditalic');
      alignCenter('(INFORMED CONSENT)', undefined, addLine(4.7));
      doc.setFont('Helvetica', 'normal');

      doc.autoTable({
        body: [
          [
            {
              content: 'IDENTITAS PASIEN',
              styles: { fontStyle: 'bold', halign: 'center', valign: 'middle' },
              colSpan: 3,
            },
          ],
          [
            'No RM',
            {
              content: patient.noRM,
              colSpan: 2,
            },
          ],
          [
            'Nama',
            {
              content: patient.name,
              colSpan: 2,
            },
          ],
          [
            'Tempat & Tanggal Lahir',
            {
              content: patient.birthPlace + ', ' + patient.birthDate,
              colSpan: 2,
            },
          ],
          [
            'Alamat',
            {
              content:
                patient.address.length > 75
                  ? patient.address.substring(0, 75) + '...'
                  : patient.address,
              colSpan: 2,
            },
          ],
          [
            {
              content: 'PEMBERIAN INFORMASI',
              styles: { fontStyle: 'bold', halign: 'center', valign: 'middle' },
              colSpan: 3,
            },
          ],
          [
            'Dokter Penanggungjawab',
            {
              content: '',
              colSpan: 2,
            },
          ],
          [
            'Pelaksana Tindakan',
            {
              content: '',
              colSpan: 2,
            },
          ],
          [
            'Pemberi Informasi',
            {
              content: '',
              colSpan: 2,
            },
          ],
          [
            'Penerima Informasi',
            {
              content: '',
              colSpan: 2,
            },
          ],
          [
            {
              content: 'JENIS INFORMASI',
              styles: { fontStyle: 'bold', halign: 'center', valign: 'middle' },
            },
            {
              content: 'ISI INFORMASI',
              styles: { fontStyle: 'bold', halign: 'center', valign: 'middle' },
            },
            {
              content: 'Tanda (     )',
              styles: { fontStyle: 'bold', halign: 'center', valign: 'middle' },
            },
          ],
          // ['Diagnosis', diagnose.value[0].name, ''],
          ['Diagnosis', '', ''],
          ['Dasar Diagnosis', '', ''],
          ['Tindakan Kedokteran', '', ''],
          ['Indikasi Tindakan', '', ''],
          ['Risiko Tindakan', '', ''],
          ['Tata Cara', '', ''],
          ['Tujuan', '', ''],
          ['Komplikasi', '', ''],
          ['Prognosis', '', ''],
          ['Alternatif dan Risiko', '', ''],
          ['Biaya', '', ''],
          ['Lain-Lain', '', ''],
          [
            {
              content:
                'Dengan ini menyatakan bahwa saya telah menerangkan hal-hal di atas secara lancar dan jujur serta memberikan kesempatan untuk bertanya dan/atau berdiskusi',
              colSpan: 2,
            },
            '',
          ],
          [
            {
              content:
                'Dengan ini menyatakan bahwa saya telah menerima informasi sebagaimana di atas, yang saya beri tanda (     ) atau paraf pada kolom kanannya dan telah memahaminya',
              colSpan: 2,
            },
            '',
          ],
          [
            {
              content:
                '(*) Bila pasien tidak berkompeten atau tidak mau menerima informasi, maka penerima informasi adalah wali/keluarga terdekat',
              colSpan: 3,
            },
          ],
        ],
        startY: addLine(5),
        styles: {
          lineColor: [0, 0, 0],
          lineWidth: 0.01,
          fillColor: false,
          textColor: [0, 0, 0],
          font: 'helvetica',
          fontSize: 9,
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        columnStyles: {
          0: { cellPadding: 0.04, cellWidth: 2 },
          1: { cellPadding: 0.04, cellWidth: 4.5 },
          2: { cellPadding: 0.04, cellWidth: 1 },
        },
        theme: 'grid',
      });
      // Check Image
      doc.addImage(checked, 'PNG', 7.535, 4.4, 0.13, 0.12);
      doc.addImage(checked, 'PNG', 6.42, 7.65, 0.13, 0.12);

      doc.setFont('Helvetica', 'bold');
      doc.setFontSize(10);
      alignCenter('TINDAKAN MEDIS', undefined, addLine(33.3));
      doc.setFont('Helvetica', 'normal');

      doc.setFontSize(9);
      this.pdfTextPrint(
        doc,
        'Saya yang bertanda tangan di bawah ini: ',
        addLine(34),
        'left',
        leftMargin,
      );

      doc.autoTable({
        body: [
          ['Nama', ':', ''],
          ['Umur', ':', ''],
          ['Alamat', ':', ''],
        ],
        startY: addLine(34.2),
        styles: {
          textColor: 0,
          halign: 'left',
          fontSize: 9,
        },
        columnStyles: {
          0: { cellWidth: 0.6, cellPadding: 0.015 },
          1: { cellWidth: 0.18, cellPadding: 0.015 },
          2: { cellWidth: 6.5, cellPadding: 0.015 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });
      let lastLine = doc.autoTable.previous.finalY;

      doc.autoTable({
        body: [
          [
            'Menyatakan dengan sesungguhnya selaku ',
            {
              content: `_____________ dari:`,
            },
          ],
        ],
        startY: lastLine + 0.01,
        styles: {
          textColor: 0,
          halign: 'left',
          fontSize: 9,
        },
        columnStyles: {
          0: { cellWidth: 2.4, cellPadding: 0.015 },
          1: { cellWidth: 4, cellPadding: 0.015 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });
      doc.autoTable({
        body: [
          ['Nama', ':', patient.name],
          ['Umur', ':', `${patient.age} Tahun`],
          [
            'Alamat',
            ':',
            patient.address.length > 75
              ? patient.address.substring(0, 75) + '...'
              : patient.address,
          ],
        ],
        startY: lastLine + 0.2,
        styles: {
          textColor: 0,
          halign: 'left',
          fontSize: 9,
        },
        columnStyles: {
          0: { cellWidth: 0.6, cellPadding: 0.015 },
          1: { cellWidth: 0.18, cellPadding: 0.015 },
          2: { cellWidth: 6.5, cellPadding: 0.015 },
        },
        margin: {
          left: leftMargin,
          right: 4,
        },
        theme: 'plain',
      });

      utils.methods.printBoldCharacters(
        doc.setFontSize(9),
        `Dengan ini menyatakan ${this.generateAgreement(
          '',
        )} dilakukan tindakan medis berupa ____________________. Saya telah memahami penjelasan perihal jenis, manfaat, dan risiko tindakan medis tersebut. Yang tujuan, sifat, dan perlunya tindakan medis tersebut di atas serta risiko yang dapat ditimbulkan telah dijelaskan oleh petugas yang bersangkutan dan saya mengerti sepenuhnya.`,
        lastLine + 0.85,
        leftMargin,
        7.8,
      );

      let statementLineEnter = lastLine + 0.9;

      alignCenter('Saksi', undefined, statementLineEnter + 0.8, -3);
      alignCenter('(_____________)', undefined, statementLineEnter + 1.4, -3);

      alignCenter('Petugas', undefined, statementLineEnter + 0.8, 0);
      alignCenter('(_____________)', undefined, statementLineEnter + 1.4, 0);

      alignCenter(
        `${
          this.$store.getters.userLoggedIn.clinic.clinicRegion
        }, _____________ ${moment().format('YYYY')}`,
        undefined,
        statementLineEnter + 0.5,
        3,
      );
      alignCenter(`_____________`, undefined, statementLineEnter + 0.8, 3);
      alignCenter('(_____________)', undefined, statementLineEnter + 1.4, 3);
      return doc;
    },

    generateSscDoc(clinic, patient, data) {
      const letterData = Constant.client.find(
        item => item.name.toLowerCase() === clinic.name.toLowerCase(),
      );
      const doc = new JsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: 'a4',
      });

      const startYMainData = 2.45;
      const signInLeftMargin = 0.4;
      const timeOutLeftMargin = 4.1;
      const signOutLeftMargin = 7.75;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;

      const checkBoxDecider = (data, x = 0, y = 0) => {
        if (typeof data !== 'boolean') {
          doc.addImage(checkboxFalse, 'PNG', x + 0.1, y - 0.075, 0.09, 0.09);
          doc.addImage(checkboxFalse, 'PNG', x + 0.8, y - 0.075, 0.09, 0.09);
          return;
        }
        if (data) {
          doc.addImage(checkboxTrue, 'PNG', x + 0.1, y - 0.075, 0.09, 0.09);
          doc.addImage(checkboxFalse, 'PNG', x + 0.8, y - 0.075, 0.09, 0.09);
          return;
        }
        if (!data) {
          doc.addImage(checkboxFalse, 'PNG', x + 0.1, y - 0.075, 0.09, 0.09);
          doc.addImage(checkboxTrue, 'PNG', x + 0.8, y - 0.075, 0.09, 0.09);
          return;
        }
      };

      const decision = (
        x,
        y,
        decision,
        text = 'Sudah                Belum',
      ) => {
        this.pdfTextPrint(doc, text, y, 'left', x + 0.25);
        checkBoxDecider(decision, x, y);
      };

      // Set line width and color
      doc.setDrawColor(156, 162, 165);
      doc.setTextColor(64, 64, 64);
      doc.setLineWidth(0.0001);
      // Generate rectangles
      doc.rect(0.3, 0.5, 4.5, 1); // letter head wrapper
      doc.rect(4.8, 0.5, 6.5, 1); // meta data wrapper
      doc.rect(0.3, 1.65, 3.67, 0.6); // sign in data & time wrapper
      doc.rect(3.975, 1.65, 3.665, 0.6); // time out data & time wrapper
      doc.rect(7.64, 1.65, 3.65, 0.6); // sign out data & time wrapper
      doc.rect(0.3, 2.25, 3.67, 4.8); // sign in main data wrapper
      doc.rect(3.975, 2.25, 3.665, 4.8); // time out main data wrapper
      doc.rect(7.64, 2.25, 3.65, 4.8); // sign out main data wrapper
      doc.rect(0.3, 7.05, 3.67, 1); // sign in main data wrapper
      doc.rect(3.975, 7.05, 3.665, 1); // time out main data wrapper
      doc.rect(7.64, 7.05, 3.65, 1); // sign out main data wrapper

      // Set main title
      doc.setFontSize(12);
      this.pdfTextPrint(
        doc,
        'FORMULIR CHECKLIST KESELAMATAN PASIEN (SURGICAL SAFETY CHECKLIST)',
        0.35,
      );

      // Set header
      doc.addImage(
        letterData.smallLetterHead.src,
        'PNG',
        0.35,
        0.52,
        letterData.smallLetterHead.width,
        letterData.smallLetterHead.height,
      );

      doc.autoTable({
        body: [
          ['Nama Pasien:', patient.name, 'Tanggal Lahir:', patient.birthDate],
          ['No RM:', patient.rmNumber, 'Umur:', `${patient.age} Tahun`],
          [
            'Jenis Kelamin:',
            `${patient.gender} (${patient.gender[0]})`,
            'Tindakan:',
            data?.signin?.action?.name ||
              '.....................................',
          ],
        ],
        columnStyles: {
          0: {
            cellWidth: 1,
          },
          1: {
            cellWidth: 2,
          },
          2: {
            cellWidth: 1,
          },
          3: {
            cellWidth: 2,
          },
        },
        styles: { fontSize: 9 },
        startY: 0.6,
        margin: { left: 5 },
        theme: 'plain',
        willDrawCell: () => {
          doc.setTextColor(64, 64, 64);
          doc.setFontSize(8);
        },
      });
      // End of header

      // Set date & date for each phase data
      doc.autoTable({
        body: [
          ['SIGN IN', 'TIME OUT', 'SIGN OUT'],
          [
            `(Tanggal ${data?.signin?.time?.date ||
              '..................'}, Pukul ${data?.signin?.time?.time ||
              '.............'})`,
            `(Tanggal ${data?.timeout?.time?.date ||
              '..................'}, Pukul ${data?.timeout?.time?.time ||
              '.............'})`,
            `(Tanggal ${data?.signout?.time?.date ||
              '..................'}, Pukul ${data?.signout?.time?.time ||
              '.............'})`,
          ],
        ],
        columnStyles: {
          0: {
            cellPadding: 0.06,
          },
          1: {
            cellPadding: 0.06,
          },
          2: {
            cellPadding: 0.06,
          },
          3: {
            cellPadding: 0.06,
          },
          4: {
            cellPadding: 0.06,
          },
          5: {
            cellPadding: 0.06,
          },
        },
        styles: {
          fontSize: 9,
          halign: 'center',
          minCellHeight: 0.1,
          minCellWidth: 1.83,
        },
        startY: 1.7,
        margin: { left: 0.3, top: 0, bottom: 0 },
        theme: 'plain',
        willDrawCell: data => {
          doc.setTextColor(64, 64, 64);
          doc.setFontSize(8);
          if (!data.row.index) {
            doc.setFont('Helvetica', 'bold');
          }
        },
      });
      // End of date & date for each phase data section

      doc.setFontSize(7);
      // Set main data for sign in phase
      this.pdfTextPrint(
        doc,
        '1. Apakah pasien telah dikonfirmasi nama, sisi, operasi, dan Informed Consent?',
        startYMainData,
        'left',
        signInLeftMargin,
      );
      decision(signInLeftMargin, addLine(7.3), data?.signin?.dataConfirmation);

      this.pdfTextPrint(
        doc,
        '2. Apakah alat anestesi dan pengobatan telah diperiksa lengkap?',
        addLine(8.3),
        'left',
        signInLeftMargin,
      );
      decision(
        signInLeftMargin,
        addLine(9.1),
        data?.signin?.isAnestheticDeviceComplitelyChecked,
      );

      this.pdfTextPrint(
        doc,
        '3. Apakah area operasi sudah ditandai?',
        addLine(10.1),
        'left',
        signInLeftMargin,
      );
      decision(
        signInLeftMargin,
        addLine(10.9),
        data?.signin?.isOperatingAreaMarked,
      );

      this.pdfTextPrint(
        doc,
        '4. Keadaan pasien yang berisiko',
        addLine(11.9),
        'left',
        signInLeftMargin,
      );

      this.pdfTextPrint(
        doc,
        'Tidak memiliki keadaan berisiko',
        addLine(12.69),
        'left',
        signInLeftMargin + 0.25,
      );
      this.pdfTextPrint(
        doc,
        'Kesulitan jalan nafas dan risiko aspirasi',
        addLine(13.66),
        'left',
        signInLeftMargin + 0.25,
      );
      this.pdfTextPrint(
        doc,
        'Risiko pendarahan > 500 cc pada dewasa dan anak 7 cc/kg BB',
        addLine(14.62),
        'left',
        signInLeftMargin + 0.25,
      );

      this.pdfTextPrint(
        doc,
        'Penyakit infeksius',
        addLine(15.66),
        'left',
        signInLeftMargin + 0.25,
      );

      doc.addImage(
        data?.signin?.risk?.includes('Tidak Ada Keadaan Berisiko')
          ? checkboxTrue
          : checkboxFalse,
        'PNG',
        signInLeftMargin + 0.1,
        addLine(12.35),
        0.09,
        0.09,
      );
      doc.addImage(
        data?.signin?.risk?.includes(
          'Kesulitan Jalan Nafas dan Risiko Aspirasi',
        )
          ? checkboxTrue
          : checkboxFalse,
        'PNG',
        signInLeftMargin + 0.1,
        addLine(13.3),
        0.09,
        0.09,
      );
      doc.addImage(
        data?.signin?.risk?.includes(
          'Risiko Pendarahan > 500 cc pada Dewasa dan Anak 7 cc/kg BB',
        )
          ? checkboxTrue
          : checkboxFalse,
        'PNG',
        signInLeftMargin + 0.1,
        addLine(14.25),
        0.09,
        0.09,
      );
      doc.addImage(
        data?.signin?.risk?.includes('Penyakit Infeksius')
          ? checkboxTrue
          : checkboxFalse,
        'PNG',
        signInLeftMargin + 0.1,
        addLine(15.3),
        0.09,
        0.09,
      );

      this.pdfTextPrint(
        doc,
        '5. Riwayat Alergi',
        addLine(16.66),
        'left',
        signInLeftMargin,
      );
      this.pdfTextPrint(
        doc,
        data?.signin?.allergy?.join(', ') || '-',
        addLine(17.5),
        'left',
        signInLeftMargin + 0.11,
        {
          maxWidth: 3.3,
          align: 'justify',
        },
      );
      // End of main data for sign in phase

      // Set main data for time out phase
      this.pdfTextPrint(
        doc,
        '1. Konfirmasi semua anggota tim, memperkenalkan nama dan tugasnya',
        startYMainData,
        'left',
        timeOutLeftMargin,
      );
      decision(
        timeOutLeftMargin,
        addLine(7.3),
        data?.timeout?.introduceTeamMemberConfirmation,
      );

      this.pdfTextPrint(
        doc,
        '2. Konfimasi nama pasien, prosedur, dan dimana insisi akan dilakukan',
        addLine(8.3),
        'left',
        timeOutLeftMargin,
      );
      decision(
        timeOutLeftMargin,
        addLine(9.1),
        data?.timeout?.incisionDataConfirmation,
      );

      this.pdfTextPrint(
        doc,
        '3. Apakah antiobiotik profilaksis sudah diberikan 60 menit sebelumnya?',
        addLine(10.1),
        'left',
        timeOutLeftMargin,
      );
      decision(
        timeOutLeftMargin,
        addLine(10.9),
        data?.timeout?.isAntibioticProphylaxisHasGiven,
      );

      this.pdfTextPrint(
        doc,
        'MENGANTISIPASI KEJADIAN KRITIS',
        addLine(12.2),
        'left',
        timeOutLeftMargin,
      );

      this.pdfTextPrint(
        doc,
        '4. Bagaimana langkah menghadapi keadaan kritis atau kejadian luar biasa',
        addLine(13.1),
        'left',
        timeOutLeftMargin,
      );
      this.pdfTextPrint(
        doc,
        data?.timeout
          ? data?.timeout?.criticalStep?.description || '-'
          : '...............................................................................................................................................................................................................................................................................................................................................................',
        addLine(13.8),
        'left',
        timeOutLeftMargin + 0.105,
        {
          maxWidth: 3.2,
          align: 'justify',
          lineHeightFactor: 1.5,
        },
      );

      this.pdfTextPrint(
        doc,
        '5. Berapa lama untuk menangani kejadian tersebut',
        addLine(16),
        'left',
        timeOutLeftMargin,
      );

      this.pdfTextPrint(
        doc,
        data?.timeout
          ? data?.timeout?.handlingDuration?.description || '-'
          : '.........................................................................................................................................................',
        addLine(16.7),
        'left',
        timeOutLeftMargin + 0.105,
        {
          maxWidth: 3.2,
          align: 'justify',
          lineHeightFactor: 1.5,
        },
      );

      this.pdfTextPrint(
        doc,
        '6. Bagaimana langkah untuk mengatasi pendarahan yang terjadi?',
        addLine(17.5),
        'left',
        timeOutLeftMargin,
      );
      this.pdfTextPrint(
        doc,
        data?.timeout
          ? data?.timeout?.bleedingHandleDuration?.description || '-'
          : '....................................................................................................................................................................................................................................................................................................................................................................................................................................................................................',
        addLine(18.2),
        'left',
        timeOutLeftMargin + 0.105,
        {
          maxWidth: 3.2,
          align: 'justify',
          lineHeightFactor: 1.5,
        },
      );

      this.pdfTextPrint(
        doc,
        '7. Apakah pasien membutuhkan perhatian khusus?',
        addLine(21.2),
        'left',
        timeOutLeftMargin,
      );
      decision(
        timeOutLeftMargin,
        addLine(22),
        data?.timeout?.isPatientNeedSpecialAttention,
        'Ya                     Tidak',
      );

      this.pdfTextPrint(
        doc,
        '8. Apakah sterilisasi (termasuk indikator hasil) sudah dikonfirmasi?',
        addLine(23),
        'left',
        timeOutLeftMargin,
      );
      decision(
        timeOutLeftMargin,
        addLine(23.8),
        data?.timeout?.isSterilizationConfirmed,
      );

      this.pdfTextPrint(
        doc,
        '9. Apakah ada permasalahan pada alat atau ada yang perlu diperhatikan?',
        addLine(24.8),
        'left',
        timeOutLeftMargin,
      );
      decision(
        timeOutLeftMargin,
        addLine(25.6),
        data?.timeout?.isProblematicTool,
        'Ya                     Tidak',
      );
      // End of main data for time out phase

      // Set main data for sign out phase
      this.pdfTextPrint(
        doc,
        '1. Apakah nama tindakan prosedur telah dikonfirmasi?',
        startYMainData,
        'left',
        signOutLeftMargin,
      );
      decision(
        signOutLeftMargin,
        addLine(7.3),
        data?.signout?.procedureActionName,
      );

      this.pdfTextPrint(
        doc,
        '2. Apakah perhitungan jumlah instrumen, kassa dan jarum sudah tepat?',
        addLine(8.3),
        'left',
        signOutLeftMargin,
      );
      decision(signOutLeftMargin, addLine(9.1), data?.signout?.instrument);

      this.pdfTextPrint(
        doc,
        '3. Dari tindakan yang dilakukan, apakah membutuhkan pengambilan specimen?',
        addLine(10.1),
        'left',
        signOutLeftMargin,
      );
      decision(
        signOutLeftMargin,
        addLine(10.9),
        data?.signout?.speciment,
        'Ya                     Tidak',
      );

      this.pdfTextPrint(
        doc,
        '4. Apakah specimen sudah diberi label (nama pasien, no register)?',
        addLine(11.9),
        'left',
        signOutLeftMargin,
      );
      decision(
        signOutLeftMargin,
        addLine(12.7),
        data?.signout?.speciment ? data?.signout?.isSpecimentLabeled : null,
      );

      this.pdfTextPrint(
        doc,
        '5. Apakah ada kesulitan dalam penggunaan peralatan medis?',
        addLine(13.7),
        'left',
        signOutLeftMargin,
      );
      decision(
        signOutLeftMargin,
        addLine(14.5),
        data?.signout?.isAnyDifficulties,
        'Ya                     Tidak',
      );

      this.pdfTextPrint(
        doc,
        '6. Apakah ada perhatian khusus untuk pemulihan dan rencana perawatan pasien?',
        addLine(15.5),
        'left',
        signOutLeftMargin,
      );
      decision(
        signOutLeftMargin,
        addLine(16.3),
        data?.signout?.isSpecialAttention,
        'Ya                     Tidak',
      );
      // End of main data for sign out phase

      // footer
      doc.autoTable({
        body: [
          ['Perawat', 'Dokter', 'Perawat', 'Dokter', 'Perawat', 'Dokter'],
          [
            data?.signin?.staff?.name || '............................',
            data?.signin?.doctor?.name || '............................',
            data?.timeout?.staff?.name || '............................',
            data?.timeout?.doctor?.name || '............................',
            data?.signout?.staff?.name || '............................',
            data?.signout?.doctor?.name || '............................',
          ],
        ],
        columnStyles: {
          0: {
            cellPadding: 0.06,
          },
          1: {
            cellPadding: 0.06,
          },
          2: {
            cellPadding: 0.06,
          },
          3: {
            cellPadding: 0.06,
          },
          4: {
            cellPadding: 0.06,
          },
          5: {
            cellPadding: 0.06,
          },
        },
        styles: {
          fontSize: 9,
          halign: 'center',
          minCellHeight: 0.6,
          minCellWidth: 1.83,
        },
        startY: 7.1,
        margin: { left: 0.3, top: 0, bottom: 0 },
        theme: 'plain',
        willDrawCell: () => {
          doc.setTextColor(64, 64, 64);
          doc.setFontSize(8);
        },
      });
      doc.deletePage(2);

      return doc;
    },

    generateAgreement(data) {
      switch (data) {
        case true:
          return '**SETUJU**';
        case false:
          return '**TIDAK SETUJU**';
        default:
          return '**SETUJU/TIDAK SETUJU**';
      }
    },

    generateOperationMark(data, patient, refImage) {
      const doc = new JsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });
      const leftMargin = 1;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1;
      const alignCenter = (text, addingLine, y, x = 0) => {
        doc.text(
          text,
          doc.internal.pageSize.getWidth() / 2 -
            doc.getTextDimensions(text).w / 2 +
            x,
          y || addLine(addingLine),
          { align: 'left', charSpace: 0 },
        );
      };

      doc.setFont('Helvetica', 'bold');
      doc.setTextColor('#404040');
      doc.setFontSize(11);
      this.pdfTextPrint(
        doc,
        'FORMULIR PENANDAAN LOKASI OPERASI/TINDAKAN MEDIS',
        1.8,
      );
      doc.setFont('Helvetica', 'normal');

      // Set line width and color
      doc.setDrawColor(194, 194, 194);
      doc.setLineWidth(0.015);
      // Generate rectangles
      doc.rect(leftMargin - 0.34, 2.2, 6.95, 1.6);

      doc.autoTable({
        body: [
          [
            'Nama Pasien:',
            ':',
            patient.name.split(',')[0].trim().length >= 20
              ? patient.name
                  .split(',')[0]
                  .trim()
                  .substring(0, 20) + '...'
              : patient.name.split(',')[0].trim(),
            'Umur',
            ':',
            `${patient.age} Tahun`,
          ],
          [
            'No RM:',
            ':',
            patient.noRM,
            'Tanggal',
            ':',
            data?.date !== undefined
              ? moment(data?.date).format('DD/MM/YYYY')
              : '.............................',
          ],
          [
            'Jenis Kelamin:',
            ':',
            patient.gender === 'Laki-laki' ? 'Laki-laki (L)' : 'Perempuan (P)',
            'Tindakan',
            ':',
            data?.action?.name.length >= 20
              ? data?.action?.name.substring(0, 20) + '...'
              : data?.action?.name || '.............................',
          ],
          [
            'Tanggal Lahir:',
            ':',
            moment(patient.birthDateUnformatted).format('DD/MM/YYYY'),
          ],
        ],
        columnStyles: {
          0: {
            cellWidth: 1.5,
          },
          1: {
            cellWidth: 0.2,
          },
          2: {
            cellWidth: 2,
          },
          3: {
            cellWidth: 1,
          },
          4: {
            cellWidth: 0.2,
          },
          5: {
            cellWidth: 1.5,
          },
        },
        styles: {
          fontSize: 10,
          textColor: '#404040',
        },
        startY: 2.4,
        margin: { left: leftMargin },
        theme: 'plain',
      });

      const inch = 96;
      const scale = 129.6 / 100;

      // Mark Body
      // Generate rectangles
      doc.rect(
        leftMargin - 0.34,
        4,
        (343 / inch) * scale,
        (273 / inch) * scale,
      );
      doc.addImage(
        refImage.mark_body.getStage().toDataURL({
          pixelRatio: 4,
        }),
        'PNG',
        1.4,
        4.15,
        (204.51 / inch) * scale,
        (241.29 / inch) * scale,
      );
      // End Mark Body

      // Mark mark_face_front
      // Generate rectangles
      doc.rect(
        leftMargin - 0.34 + (343 / inch) * scale,
        4,
        (172 / inch) * scale,
        (91 / inch) * scale,
      );
      doc.addImage(
        refImage.mark_face_front.getStage().toDataURL({
          pixelRatio: 4,
        }),
        'PNG',
        1.1 + (343 / inch) * scale,
        4.2,
        (108.34 / inch) * scale,
        (55.942 / inch) * scale,
      );
      // End Mark mark_face_front

      // Mark mark_face_side
      // Generate rectangles
      doc.rect(
        leftMargin - 0.34 + (343 / inch) * scale,
        4 + (91 / inch) * scale,
        (172 / inch) * scale,
        (91 / inch) * scale,
      );
      doc.addImage(
        refImage.mark_face_side.getStage().toDataURL({
          pixelRatio: 4,
        }),
        'PNG',
        1.1 + (343 / inch) * scale,
        5.5,
        (108.346 / inch) * scale,
        (53.454 / inch) * scale,
      );
      // End Mark mark_face_side

      // Mark mark_palm_upper
      // Generate rectangles
      doc.rect(
        leftMargin - 0.34 + (343 / inch) * scale,
        4 + (91 / inch) * scale + (91 / inch) * scale,
        (172 / inch) * scale,
        (91 / inch) * scale,
      );
      doc.addImage(
        refImage.mark_palm_upper.getStage().toDataURL({
          pixelRatio: 4,
        }),
        'PNG',
        1.1 + (343 / inch) * scale,
        6.6,
        (108.346 / inch) * scale,
        (63.357 / inch) * scale,
      );
      // End Mark mark_palm_upper

      // Mark mark_foot_lower
      // Generate rectangles
      doc.rect(
        leftMargin - 0.34,
        4 + (91 / inch) * scale + (91 / inch) * scale + (91 / inch) * scale,
        (172 / inch) * scale,
        (91 / inch) * scale,
      );
      doc.addImage(
        refImage.mark_foot_lower.getStage().toDataURL({
          pixelRatio: 4,
        }),
        'PNG',
        1.3,
        7.8,
        (72.231 / inch) * scale,
        (69.652 / inch) * scale,
      );
      // End Mark mark_foot_lower

      // Mark mark_foot_upper
      // Generate rectangles
      doc.rect(
        leftMargin - 0.34 + (172 / inch) * scale,
        4 + (91 / inch) * scale + (91 / inch) * scale + (91 / inch) * scale,
        (171 / inch) * scale,
        (91 / inch) * scale,
      );
      doc.addImage(
        refImage.mark_foot_upper.getStage().toDataURL({
          pixelRatio: 4,
        }),
        'PNG',
        3.6,
        7.85,
        (71.811 / inch) * scale,
        (61.444 / inch) * scale,
      );
      // End Mark mark_foot_upper

      // Mark mark_palm_lower
      // Generate rectangles
      doc.rect(
        leftMargin - 0.34 + (172 / inch) * scale + (171 / inch) * scale,
        4 + (91 / inch) * scale + (91 / inch) * scale + (91 / inch) * scale,
        (172 / inch) * scale,
        (91 / inch) * scale,
      );
      doc.addImage(
        refImage.mark_palm_lower.getStage().toDataURL({
          pixelRatio: 4,
        }),
        'PNG',
        5.75,
        7.8,
        (108.346 / inch) * scale,
        (63.518 / inch) * scale,
      );
      // End Mark mark_palm_lower

      doc.autoTable({
        body: [
          [
            'Saya menyatakan bahwa lokasi operasi atau tindakan medis yang telah ditetapkan pada gambar di atas adalah benar',
          ],
        ],
        columnStyles: {
          0: {
            cellWidth: 7,
          },
        },
        styles: {
          fontSize: 11.5,
          textColor: '#404040',
        },
        startY: 9.1,
        margin: { left: leftMargin - 0.34 },
        theme: 'plain',
      });

      alignCenter(
        `${this.$store.getters.userLoggedIn.clinic.clinicRegion}, ${moment(
          this.timestamps,
        )
          .locale('id')
          .format('DD MMMM YYYY')}`,
        undefined,
        9.8,
        2.5,
      );

      var doctorLength = data?.doctor_executor?.name?.length * 0.045;
      var sipLength = data?.doctor_executor?.sip?.length * 0.045;
      var lineLength = Math.max(doctorLength, sipLength);
      doc.setFont('Helvetica', 'bold');
      alignCenter('Dokter', undefined, 10.2, -2);
      doc.setFont('Helvetica', 'normal');
      alignCenter(
        data?.doctor_executor?.name || '.....................................',
        undefined,
        11.2,
        -2,
      );
      if (data?.doctor_executor?.name) {
        doc.setDrawColor(64, 64, 64);
        doc.setLineWidth(0.01);
        doc.line(2.2 - lineLength, 11.25, 2.1 + lineLength, 11.25);
        if (data?.doctor_executor?.sip) {
          doc.text(
            `SIP :  ${data?.doctor_executor?.sip}`,
            2.2 - lineLength,
            11.45,
          );
        }
      }

      const signerName =
        data?.signer_name === ''
          ? data?.signer_name_patient
          : data?.signer_name;
      const signerLength = signerName?.length * 0.04;
      doc.setFont('Helvetica', 'bold');
      alignCenter(
        data?.signer.charAt(0).toUpperCase() + data?.signer.slice(1) ||
          'Pasien/Keluarga',
        undefined,
        10.2,
        2,
      );
      doc.setFont('Helvetica', 'normal');
      alignCenter(
        data?.signer_name ||
          data?.signer_name_patient ||
          '.....................................',
        undefined,
        11.2,
        2,
      );
      if (data?.signer_name || data?.signer_name_patient) {
        doc.setDrawColor(64, 64, 64);
        doc.setLineWidth(0.01);
        doc.line(6.19 - signerLength, 11.25, 6.09 + signerLength, 11.25);
      }

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Head
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
          'in',
        );
      }
      return doc;
    },
  },
};
