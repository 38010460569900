<template>
  <v-form ref="form">
    <div class="grid">
      <label class="required">Nama Obat</label>
      <v-combobox
        ref="drugName"
        placeholder="Masukkan Nama Obat"
        item-value="id_stock"
        append-icon="mdi-chevron-down"
        class="mb-1"
        dense
        single-line
        return-object
        @change="mapPackage()"
        :readonly="isSaved"
        :filter="comboboxFilter"
        :rules="
          isSaved
            ? []
            : [
                v => v?.stock !== 0 || 'Obat habis. Pilih obat lainnya',
                v =>
                  (v?.stock >= (Number(form.amount_given) || 0) &&
                    v?.stock !== 0) ||
                  'Obat tidak cukup',
              ]
        "
        :loading="loading.drugs"
        v-model="form.drug"
        :search-input.sync="search"
        :items="resource.drugs"
      >
        <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title>
              <v-layout>
                <v-flex d-flex justify-start>
                  <span class="text-left">
                    {{ `${data.item.text}` }}
                  </span>
                </v-flex>
              </v-layout>
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-layout>
                <span class="mr-4">
                  Exp:
                  {{ data.item.expiryDateFormatted }}
                </span>
                <span class="mr-4">
                  No Batch: {{ data.item.batchNumber }}
                </span>
                <span class="mr-4">{{
                  `Sisa Stok : ${data.item.stock} ${data.item.packaging}`
                }}</span>
                <span>{{ currency(data.item.price || 0) }}</span>
              </v-layout>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-combobox>
      <label>Dosis</label>
      <span>{{
        form.drug === '' || form.drug === undefined
          ? ''
          : (form.drug?.strength || '') + ' ' + (form.drug?.strength_unit || '')
      }}</span>
      <label class="my-4">Jumlah Diminta</label>
      <span class="my-4">{{
        form.drug === '' || form.drug === undefined
          ? ''
          : (form.amount_requested || 0) + ' ' + (form.drug?.packaging || '')
      }}</span>
      <label class="my-3 required">Jumlah Diberikan</label>
      <v-text-field
        dense
        single-line
        required
        type="number"
        :readonly="isSaved"
        :rules="isSaved ? [] : rule"
        v-model="form.amount_given"
        :suffix="form.drug?.packaging || ''"
      />
      <label class="required">Aturan Pakai</label>
      <div class="d-flex">
        <div>
          <v-radio-group
            class="ma-0 pa-0"
            light
            dense
            :readonly="isSaved"
            v-model="form.radio_usage"
          >
            <v-radio class="mx-0 mb-5 pt-2" light :value="false"></v-radio>
            <v-radio class="mx-0" light :value="true"></v-radio>
          </v-radio-group>
        </div>
        <div>
          <div class="grid-usage-rule">
            <v-combobox
              :disabled="form.radio_usage"
              :rules="!form.radio_usage && !isSaved ? rule : []"
              v-model="form.usage.dropdownInput.day"
              :items="resource.usageOne"
              :readonly="isSaved"
              append-icon="mdi-chevron-down"
              dense
              auto-select-first
            />
            <v-icon small color="black">mdi-close</v-icon>
            <v-combobox
              :disabled="form.radio_usage"
              :rules="!form.radio_usage && !isSaved ? rule : []"
              v-model="form.usage.dropdownInput.usage"
              :items="resource.usageTwo"
              :readonly="isSaved"
              append-icon="mdi-chevron-down"
              dense
              auto-select-first
            />
            <v-combobox
              :disabled="form.radio_usage"
              :rules="!form.radio_usage && !isSaved ? rule : []"
              v-model="form.usage.dropdownInput.package"
              :items="resource.packaging"
              :readonly="isSaved"
              dense
              auto-select-first
              clearable
              append-icon="mdi-chevron-down"
            />
          </div>
          <div class="grid-usage-rule">
            <v-text-field
              dense
              single-line
              :readonly="isSaved"
              :rules="form.radio_usage && !isSaved ? rule : []"
              :disabled="!form.radio_usage"
              v-model="form.usage.manualInput.usage"
            ></v-text-field>
            <v-combobox
              :rules="form.radio_usage && !isSaved ? rule : []"
              :disabled="!form.radio_usage"
              v-model="form.usage.manualInput.package"
              :items="resource.packaging"
              :readonly="isSaved"
              dense
              auto-select-first
              clearable
              append-icon="mdi-chevron-down"
            />
          </div>
        </div>
      </div>
      <label>Aturan Umum</label>
      <v-autocomplete
        v-model="form.general_rule"
        :items="resource.general_rules"
        :readonly="isSaved"
        append-icon="mdi-chevron-down"
        placeholder="Pilih Aturan Umum"
        dense
      />
      <label>Rute</label>
      <v-autocomplete
        v-model="form.route"
        :items="resource.route"
        :readonly="isSaved"
        append-icon="mdi-chevron-down"
        placeholder="Pilih Rute"
        dense
        auto-select-first
      ></v-autocomplete>
      <label>Aturan Lainnya</label>
      <v-text-field
        single-line
        dense
        :readonly="isSaved"
        v-model="form.description"
      />
    </div>
  </v-form>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { getDrugsStock } from '@/fetchApi/Stock';
import formatMixin from '@/mixin/formatMixin';
import resources from '@/helper/pharmacyResources.js';

const { mapGetters } = createNamespacedHelpers('DrugRequestStore');
const _ = require('lodash');
const moment = require('moment-timezone');
export default {
  mixins: [formatMixin],
  data() {
    return {
      search: '',
      loading: {
        drugs: false,
      },
      resource: {
        drugs: [],
        usageOne: resources.usageOne,
        usageTwo: resources.usageTwo,
        packaging: resources.packaging.nonRacik,
        general_rules: resources.general_rules,
        route: resources.route,
        schedule: resources.schedule,
      },
      rule: [v => !!v || 'Wajib diisi'],
    };
  },
  watch: {
    search() {
      this.searchDrug(this);
    },
    'form.amount_given': {
      handler() {
        return this.$refs.drugName.validate();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['getForm', 'getDrugReview']),
    form: {
      get() {
        return this.getForm.nonMix;
      },
    },
    isSaved: {
      get() {
        return this.getDrugReview.isSaved;
      },
    },
  },
  mounted() {
    this.getDrugs();
  },
  methods: {
    comboboxFilter(_, __, itemText) {
      return itemText;
    },
    searchDrug: _.debounce(v => v.getDrugs(v.search), 600),
    mapPackage() {
      this.form.usage.dropdownInput.package = this.form.drug?.preparation;
    },
    async getDrugs(keyword = '') {
      try {
        this.loading.drugs = true;
        const params = {
          page: 1,
          itemCount: 25,
          category: 'Obat',
          name: keyword || '',
        };
        const response = await getDrugsStock(params);
        const { data } = response.data;
        this.resource.drugs = data.map(arr => {
          return {
            id: arr.id_drugs,
            batchNumber: arr.batch_number,
            id_stock: arr._id,
            name: arr.drugs.detail.name,
            text: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            displayName: `${arr.drugs.detail.name} ${arr.drugs.detail.strength} ${arr.drugs.detail.strength_unit} (${arr.drugs.detail.factory})`,
            strength_unit: arr.drugs.detail.strength_unit,
            strength: arr.drugs.detail.strength,
            dosage_needed: arr.drugs.detail.strength,
            preparation: arr.drugs.detail.preparation,
            packaging: arr.package,
            price: arr.price,
            pricePerItem: arr.price,
            expiry: arr.expiry_date,
            expiryDateFormatted: moment(arr.expiry_date, 'YYYY-MM-DD').format(
              'DD-MM-YYYY',
            ),
            stock: arr.quantity,
          };
        });
      } catch {
        this.resource.drugs = [];
      } finally {
        this.loading.drugs = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
  grid-template-columns: 40% 60%;
  column-gap: 0.8vw;
  align-items: start;
  text-align: start;
  width: 95%;
  color: #101010;

  #{&}-usage-rule:nth-child(1) {
    display: grid;
    grid-template-columns: 1fr 0.3vw repeat(2, 1fr);
    column-gap: 0.8vw;
  }
  #{&}-usage-rule:nth-child(2) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 0.8vw;
  }

  label {
    font-size: 0.72vw;
  }
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}

.required::after {
  content: '*';
  color: #eb4747;
}
</style>
