<template>
  <v-form ref="objectiveForm">
    <v-row>
      <v-col cols="6">
        <v-row
          no-gutters
          align="baseline"
          :class="isEmr ? 'grid-display-emr' : 'grid-display'"
        >
          <label class="required">Tekanan Darah</label>
          <div class="d-flex">
            <v-text-field
              validate-on-blur
              dense
              placeholder="Sistolik"
              :rules="
                isEditable ? [...rules, rangeRules(sistole, 'sistole')] : []
              "
              v-model="sistole"
              id="sistole"
              type="number"
              :readonly="!isEditable"
            />
            <span class="title mx-4 grey--text">/</span>
            <v-text-field
              validate-on-blur
              dense
              :rules="
                isEditable ? [...rules, rangeRules(diastole, 'diastole')] : []
              "
              placeholder="Diastolik"
              type="number"
              suffix="mmhg"
              v-model="diastole"
              id="diastole"
              :readonly="!isEditable"
            />
          </div>

          <label class="required">Nadi</label>
          <v-text-field
            validate-on-blur
            dense
            placeholder="Nadi"
            type="number"
            suffix="x/menit"
            v-model="pulse"
            :rules="
              isEditable ? [...rules, rangeRules(pulse, 'heart-rate')] : []
            "
            id="pulse"
            :readonly="!isEditable"
          />

          <label class="required">Berat Badan</label>
          <v-text-field
            validate-on-blur
            dense
            placeholder="Berat Badan"
            suffix="kg"
            type="number"
            v-model="weight"
            :rules="isEditable ? rules : []"
            id="weight"
            :readonly="!isEditable"
          />

          <label class="required">Tinggi Badan</label>
          <v-text-field
            validate-on-blur
            dense
            placeholder="Tinggi Badan"
            type="number"
            suffix="cm"
            :rules="isEditable ? [...rules, rangeRules(height, 'height')] : []"
            v-model="height"
            id="height"
            :readonly="!isEditable"
          />
          <label>Lingkar Perut</label>
          <v-text-field
            validate-on-blur
            dense
            placeholder="Lingkar perut"
            type="number"
            suffix="cm"
            v-model="bellyCircumference"
            id="lingkar-perut"
            :readonly="!isEditable"
          />
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row
          no-gutters
          align="baseline"
          :class="isEmr ? 'grid-display-emr' : 'grid-display'"
        >
          <label for="RR" class="required">RR</label>
          <v-text-field
            validate-on-blur
            dense
            placeholder="RR"
            :rules="isEditable ? [...rules, rangeRules(respiration, 'rr')] : []"
            suffix="x/menit"
            type="number"
            v-model="respiration"
            id="respiration"
            :readonly="!isEditable"
          />
          <label for="Saturasi 02">Saturasi O<sub>2</sub></label>
          <v-text-field
            validate-on-blur
            dense
            type="number"
            placeholder="Saturasi 02"
            suffix="%"
            v-model="saturation"
            id="saturation"
            :readonly="!isEditable"
          />
          <label for="Suhu">Suhu</label>
          <v-text-field
            validate-on-blur
            dense
            placeholder="Suhu Tubuh"
            :rules="isEditable ? [rangeRules(temperature, 'temperature')] : []"
            suffix="°C"
            v-model="temperature"
            type="number"
            id="temperature"
            :readonly="!isEditable"
          />
          <label class="required">Kesadaran</label>
          <v-select
            validate-on-blur
            dense
            placeholder="Pilih Kesadaran"
            :items="resource.awareness"
            item-value="code"
            item-text="name"
            :append-icon="!isEmr && isEditable ? 'mdi-chevron-down' : ''"
            v-model="awareness"
            :rules="isEditable ? rules : []"
            id="awareness"
            :clearable="isEditable"
            :readonly="!isEditable"
          />
          <label v-if="isOther">Data Objektif Lain</label>
          <v-textarea
            v-if="isOther"
            no-resize
            outlined
            dense
            placeholder="Masukkan keterangan"
            v-model="other"
            :readonly="!isEditable"
            :rules="[v => (v || '').length <= 3000 || 'Maksimal 3000 karakter']"
          />
          <label v-if="isKia">DJJ</label>
          <v-text-field
            validate-on-blur
            v-if="isKia"
            dense
            placeholder="DJJ"
            suffix="x/menit"
            v-model="djj"
            id="djj"
            :readonly="!isEditable"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Constant from '@/const';
import { createNamespacedHelpers } from 'vuex';

const axios = require('axios');
const { mapGetters, mapMutations } = createNamespacedHelpers(
  'ServiceAssessmentObjectiveData',
);
export default {
  name: 'ServiceAssessmentObjectiveData',
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
    isKia: {
      type: Boolean,
      default: false,
    },
    isOther: {
      type: Boolean,
      default: false,
    },
    isEmr: Boolean,
  },
  data() {
    return {
      rules: [v => !!v || 'Wajib diisi'],
      resource: {
        awareness: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      'getSistol',
      'getDiastol',
      'getPulse',
      'getWeight',
      'getHeight',
      'getBellyCircumference',
      'getRespirationRate',
      'getSaturation',
      'getTemperature',
      'getAwareness',
      'getOther',
      'getDjj',
    ]),
    sistole: {
      get() {
        return this.getSistol;
      },
      set(value) {
        this.setSistol(value);
      },
    },
    diastole: {
      get() {
        return this.getDiastol;
      },
      set(value) {
        this.setDiastol(value);
      },
    },
    weight: {
      get() {
        return this.getWeight;
      },
      set(value) {
        this.setWeight(value);
      },
    },
    height: {
      get() {
        return this.getHeight;
      },
      set(value) {
        this.setHeight(value);
      },
    },
    bellyCircumference: {
      get() {
        return this.getBellyCircumference;
      },
      set(value) {
        this.setBellyCircumference(value);
      },
    },
    respiration: {
      get() {
        return this.getRespirationRate;
      },
      set(value) {
        this.setRespirationRate(value);
      },
    },
    saturation: {
      get() {
        return this.getSaturation;
      },
      set(value) {
        this.setSaturation(value);
      },
    },
    temperature: {
      get() {
        return this.getTemperature;
      },
      set(value) {
        this.setTemperature(value);
      },
    },
    pulse: {
      get() {
        return this.getPulse;
      },
      set(value) {
        this.setPulse(value);
      },
    },
    awareness: {
      get() {
        return this.getAwareness;
      },
      set(value) {
        this.setAwareness(value);
      },
    },
    other: {
      get() {
        return this.getOther;
      },
      set(value) {
        this.setOther(value);
      },
    },
    djj: {
      get() {
        return this.getDjj;
      },
      set(value) {
        this.setDjj(value);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.showAwareness();
    });
  },
  beforeDestroy() {
    this.clearObjectiveState();
  },
  methods: {
    showAwareness() {
      axios.get(Constant.apiUrl.concat('/master/awarness')).then(response => {
        const data = response.data.data;
        this.resource.awareness = data.map(v => {
          return {
            code: v.code,
            name: v.description,
          };
        });
      });
    },
    ...mapMutations([
      'clearObjectiveState',
      'setSistol',
      'setDiastol',
      'setPulse',
      'setWeight',
      'setHeight',
      'setBellyCircumference',
      'setRespirationRate',
      'setSaturation',
      'setTemperature',
      'setAwareness',
      'setDjj',
      'setOther',
    ]),
    rangeRules(value, type) {
      switch (type) {
        case 'sistole':
          if (value < 40 || value > 250) {
            return 'Angka tekanan darah tidak valid';
          }
          break;
        case 'diastole':
          if (value < 30 || value > 180) {
            return 'Angka tekanan darah tidak valid';
          }
          break;
        case 'heart-rate':
          if (value < 30 || value > 160) {
            return 'Angka nadi tidak valid';
          }
          break;
        case 'rr':
          if (value < 5 || value > 70) {
            return 'Angka RR tidak valid';
          }
          break;
        case 'temperature':
          if ((value < 20 || value > 45) && value) {
            return 'Angka suhu tidak valid';
          }
          break;
        case 'height':
          if (value <= 1 || value > 300) {
            return 'Angka tinggi badan tidak valid';
          }
          break;

        case 'weight':
          if (value <= 1 || value > 600) {
            return 'Angka berat badan tidak valid';
          }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.grid-display {
  @include grid-display(10vw minmax(16vw, 26vw), true);
  column-gap: 1vw;
}

.grid-display-emr {
  @include grid-display(10vw minmax(16vw, 34vw), true);
  column-gap: 1vw;
}

.required:after {
  content: ' *';
  color: red;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
