<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="font-weight-bold">
        <span v-if="state === 'add'">Input</span>
        <span v-if="state === 'edit'">Ubah</span>
        <span v-if="state === 'detail'">Detail</span> Barang Rumah Tangga</span
      >
      <v-btn absolute right icon @click="onClose()">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text style=" padding-right: 5vw">
      <v-container style="height: 70vh; overflow: auto;" class="scrollbar">
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-form ref="form" class="col-12">
              <v-row no-gutters>
                <v-col cols="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        for="name"
                        :class="state !== 'detail' ? 'required' : ''"
                      >
                        Nama Barang
                      </label>
                    </div>
                    <v-text-field
                      :readonly="state === 'detail'"
                      placeholder="Nama Barang"
                      v-model="form.name"
                      id="name"
                      class="detail-input__input-text"
                      required
                      :rules="rules.goodsName"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        for="barcode"
                        :class="state !== 'detail' ? 'required' : ''"
                        >Barcode</label
                      >
                    </div>
                    <v-text-field
                      :readonly="state === 'detail'"
                      placeholder="Barcode"
                      id="barcode"
                      v-model="form.barcode"
                      class="detail-input__input-text"
                      :rules="rules.barcode"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        for="category"
                        :class="state !== 'detail' ? 'required' : ''"
                        >Kategori</label
                      >
                    </div>
                    <v-combobox
                      :readonly="state === 'detail'"
                      placeholder="Kategori"
                      class="detail-input__input-text"
                      v-model="form.category"
                      :items="items.category.data"
                      :loading="items.category.isLoading"
                      :rules="rules.category"
                      :append-icon="
                        state === 'detail' ? '' : 'mdi-chevron-down'
                      "
                    ></v-combobox>
                  </div>
                </v-col>
                <v-col cols="5">
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="hpp">HPP</label>
                    </div>
                    <v-currency-field
                      :readonly="state === 'detail'"
                      id="hpp"
                      v-model="form.hpp"
                      prefix="Rp. "
                      class="detail-input__input-text"
                      :rules="rules.hpp"
                    />
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="hna">HNA</label>
                    </div>
                    <v-currency-field
                      :readonly="state === 'detail'"
                      id="hna"
                      v-model="form.hna"
                      prefix="Rp. "
                      class="detail-input__input-text"
                      :rules="rules.hna"
                    />
                  </div>

                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label
                        for="pabrik"
                        :class="state !== 'detail' ? 'required' : ''"
                        >Pabrik</label
                      >
                    </div>
                    <v-combobox
                      :readonly="state === 'detail'"
                      placeholder="Pabrik"
                      class="detail-input__input-text"
                      v-model="form.factory"
                      :items="items.factory.data"
                      :loading="items.factory.isLoading"
                      :rules="rules.factory"
                      :append-icon="
                        state === 'detail' ? '' : 'mdi-chevron-down'
                      "
                    ></v-combobox>
                  </div>
                  <div class="detail-input">
                    <div class="detail-input__label">
                      <label for="pabrik">Minimal Stok</label>
                    </div>
                    <v-text-field
                      :readonly="state === 'detail'"
                      class="detail-input__input-text"
                      v-model="form.min_stock"
                    ></v-text-field>
                  </div>
                  <div class="detail-input">
                    <div
                      class="detail-input__label detail-input__label--position"
                    >
                      <label for="pabrik">Aktif</label>
                    </div>
                    <v-checkbox
                      :readonly="state === 'detail'"
                      v-model="form.isActive"
                    ></v-checkbox>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <v-col md="10" offset-md="1" class="d-flex align-center">
            <template>
              <v-data-table
                :headers="packaging_header"
                :items="goods.packaging"
                :items-per-page="goods.packaging.length"
                sort-by="calories"
                hide-default-footer
                class="table-kemasan"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Kemasan</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="state !== 'detail'"
                          color="primary"
                          dark
                          class="mb-2 text-none"
                          v-bind="attrs"
                          v-on="on"
                          depressed
                        >
                          Tambah Kemasan
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Tambah Kemasan</span>
                        </v-card-title>

                        <v-container>
                          <v-row align-content="center" justify="center">
                            <v-col cols="12" class="d-flex justify-center">
                              <v-combobox
                                :readonly="state === 'detail'"
                                label="Kemasan"
                                class="padding"
                                dense
                                hide-details
                                single-line
                                menu-props="auto"
                                clearable
                                v-model="editedItem.package"
                                :items="items.packages.data"
                                :loading="items.packages.isLoading"
                              ></v-combobox>
                              <v-text-field
                                :readonly="state === 'detail'"
                                class="padding"
                                dense
                                v-model="editedItem.quantity"
                                label="Jumlah"
                                placeholder="0"
                              />
                              <v-combobox
                                :readonly="state === 'detail'"
                                label="Satuan"
                                class="padding"
                                dense
                                hide-details
                                single-line
                                menu-props="auto"
                                clearable
                                v-model="editedItem.package_unit"
                                :items="items.packages.data"
                                :loading="items.packages.isLoading"
                              ></v-combobox>
                              <v-checkbox
                                class="padding"
                                dense
                                v-model="editedItem.isDefault"
                                label="Satuan Terkecil"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" @click="close" text
                            >Cancel</v-btn
                          >
                          <v-btn color="primary" @click="save" text>Save</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.isDefault`]="{ item }">
                  <span>{{ item.isDefault ? 'Ya' : 'Tidak' }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    v-if="state !== 'detail'"
                    small
                    class="mr-2"
                    @click="editItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    v-if="state !== 'detail'"
                    small
                    @click="deleteItem(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn width="100" text class="text-none" @click="onClose()">
        Kembali
      </v-btn>
      <v-btn
        v-if="state !== 'detail'"
        color="primary"
        width="100"
        class="text-none"
        depressed
        @click="addGoods()"
      >
        Simpan
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
// import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('MasterGoods');

// const axios = require('axios');
export default {
  name: 'InputNewGoods',
  mixins: [jwtMixin],
  props: {
    state: {
      type: String,
      default: 'add',
    },
  },
  data() {
    return {
      dialog: false,
      defaultItem: {
        isDefault: false,
        package: '',
        quantity: '',
        package_unit: '',
      },
      editedItem: {
        isDefault: false,
        package: '',
        quantity: '',
        package_unit: '',
      },
      editedIndex: -1,
      packaging_header: [
        { text: 'Kemasan', value: 'package', align: 'center' },
        { text: 'Jumlah', value: 'quantity', align: 'center' },
        { text: 'Satuan', value: 'package_unit', align: 'center' },
        { text: 'Kemasan Terkecil', value: 'isDefault', align: 'center' },
        { text: '', value: 'actions' },
      ],
      goods: {
        packaging: [],
      },
      rules: {
        goodsName: [v => !!v || 'Masukkan nama barang'],
        barcode: [v => !!v || 'Masukkan barcode'],
        category: [v => !!v || 'Pilih kategori'],
        preparation: [v => !!v || 'Pilih sediaan'],
        factory: [v => !!v || 'Pilih pabrik'],
        hpp: [v => !!v || 'Masukkan HPP'],
        hna: [v => !!v || 'Masukkan HNA'],
      },
    };
  },
  computed: {
    ...mapGetters(['getItems', 'getForm']),
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Kemasan' : 'Ubah Kemasan';
    },
    items: {
      get() {
        return this.getItems;
      },
    },
    form: {
      get() {
        return this.getForm;
      },
    },
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
  },
  mounted() {
    switch (this.state) {
      case 'add':
        this.$nextTick(() => {
          this.runApiCalls(() => {
            this.resolveGetFactory();
            this.resolveGetCategoryByRT();
            this.resolveGetPackagesByMenu();
          });
        });
        break;
      case 'edit':
        this.$nextTick(() => {
          this.runApiCalls(() => {
            this.resolveGetGoodsById().then(
              item => (this.goods.packaging = item.packaging),
            );
            this.resolveGetFactory();
            this.resolveGetCategoryByRT();
            this.resolveGetPackagesByMenu();
          });
        });
        break;
      case 'detail':
        this.$nextTick(() => {
          this.runApiCalls(() => {
            this.rules = {
              goodsName: [],
              barcode: [],
              category: [],
              preparation: [],
              factory: [],
              hpp: [],
              hna: [],
            };
            this.resolveGetGoodsById().then(
              item => (this.goods.packaging = item.packaging),
            );
          });
        });
        break;
    }
  },
  methods: {
    ...mapActions([
      'resolvePutGoods',
      'resolvePostGoods',
      'resolveGetFactory',
      'resolveGetGoodsById',
      'resolveGetCategoryByRT',
      'resolveGetPackagesByMenu',
    ]),
    onClose() {
      this.$emit('close-dialog');
    },

    editItem(item) {
      this.editedIndex = this.goods.packaging.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.goods.packaging.indexOf(item);
      this.goods.packaging.splice(index, 1);
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (
        this.editedItem.package !== '' &&
        this.editedItem.quantity !== '' &&
        this.editedItem.package_unit !== ''
      ) {
        if (this.editedIndex > -1) {
          Object.assign(
            this.goods.packaging[this.editedIndex],
            this.editedItem,
          );
        } else {
          this.goods.packaging.push(this.editedItem);
        }
        this.close();
      }
    },
    addGoods() {
      if (!this.$refs.form.validate()) return;
      switch (this.state) {
        case 'add':
          this.resolvePostGoods(this.goods.packaging).then(status => {
            if (status === 200) {
              Swal.fire(
                'Berhasil',
                'Data barang berhasil ditambahkan',
                'success',
              );
            } else {
              Swal.fire('Gagal', 'Data barang gagal ditambahkan', 'error');
            }
          });
          break;
        case 'edit':
          this.resolvePutGoods(this.goods.packaging).then(status => {
            if (status === 200) {
              Swal.fire(
                'Berhasil',
                'Data barang berhasil diperbaharui',
                'success',
              );
            } else {
              Swal.fire('Gagal', 'Data barang gagal diperbaharui', 'error');
            }
          });
          break;
      }
      this.$emit('update-data');
    },
  },
};
</script>

<style lang="scss" scoped>
.scrollbar {
  &::-webkit-scrollbar {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(214, 214, 214);
  }
  &::-webkit-scrollbar-thumb {
    width: 0.4rem;
    border-radius: 1rem;
    background-color: rgb(146, 146, 146);
  }
}
.column {
  margin: 0 auto;
}

.detail-radio {
  display: flex;
  justify-content: flex-end;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-around;

  &__label {
    justify-content: flex-end;
    flex: 1;
    display: flex;
    color: #222;

    &--position {
      position: relative;
      right: 140px;
    }
  }

  &__input-text {
    margin-left: 10px;
    max-width: 40%;
  }
}

.padding {
  padding-left: 10px;
}

.v-input__control {
  display: flex;
  align-self: center;
}

.radio {
  margin: 0;
}

.table-kemasan {
  width: 100%;
  margin-top: 20px;
}

.required:after {
  content: ' *';
  color: red;
}
</style>
