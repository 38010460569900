<template>
  <div class="text-start">
    <div class="d-flex align-center">
      <span class="mr-4">Resep ODD</span>
      <v-switch
        v-model="form.schedule.isOdd"
        :readonly="isSaved"
        class="ma-0 pa-0"
        light
        dense
        hide-details
      ></v-switch>
    </div>
    <div class="schedule-container-grid">
      <div
        class="schedule-grid"
        :class="`area-${index}`"
        v-for="(item, index) in form.schedule.schedule"
        :key="`schedule-${index}`"
      >
        <v-checkbox
          :label="item.name"
          v-model="item.check"
          :disabled="!form.schedule.isOdd && isSaved"
          class="text-capitalize"
          light
          dense
          hide-details
        />
        <v-text-field
          dense
          single-line
          light
          :rules="rule"
          @input="formatTime(item.time, index)"
          v-model="item.time"
          :disabled="(!item.check || !form.schedule.isOdd) && isSaved"
          :placeholder="item.time"
        />
      </div>
    </div>
    <span>Alasan Konfirmasi</span>
    <v-textarea
      placeholder="Isi Alasan Konfirmasi"
      background-color="white"
      v-model="form.schedule.confirmationReason"
      :readonly="isSaved"
      no-resize
      dense
      single-line
    >
    </v-textarea>
    <div class="drug-price">
      <div class="d-flex">
        <v-icon class="mr-2">mdi-shopping-outline</v-icon>
        <span>Harga Obat</span>
      </div>
      <span>{{ currency(price) }}</span>
    </div>
    <div class="d-flex">
      <v-btn
        class="text-capitalize button px-3 mr-3"
        color="primary"
        :disabled="isSaved"
        :loading="loading"
        @click="saveDrug()"
        outlined
        depressed
      >
        <v-icon v-if="!form.isEdit" class="mr-1" small>mdi-plus</v-icon
        ><span style="letter-spacing: .3px;" class="primary--text">{{
          form.isEdit ? 'Simpan Perubahan' : 'Tambahkan Obat'
        }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import formatMixin from '@/mixin/formatMixin';
import { putPharmacyDrug } from '@/fetchApi/Pharmacy';
import Swal from 'sweetalert2';

const { mapGetters, mapActions, mapMutations } = createNamespacedHelpers(
  'DrugRequestStore',
);
const moment = require('moment-timezone');
export default {
  mixins: [formatMixin],
  props: {
    refs: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      rule: [v => this.checkTimeFormat(v) || 'Waktu tidak valid'],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['getForm', 'getRecipe', 'getTab', 'getDrugReview']),
    isSaved: {
      get() {
        return this.getDrugReview.isSaved;
      },
    },
    isTakeHome: {
      get() {
        return this.getTab ? true : false;
      },
    },
    pharmacyType: {
      get() {
        const pharmtype = this.$route.path.split('/')[2];
        switch (pharmtype) {
          case 'rawat-inap':
            return 'rawat_inap';

          case 'rawat-jalan':
            return 'rawat_jalan';

          case 'igd':
            return 'igd';

          default:
            return '';
        }
      },
    },
    drugs: {
      get() {
        return this.getRecipe;
      },
    },
    form: {
      get() {
        return this.getForm;
      },
    },
    price: {
      get() {
        return (
          (this.form.type
            ? this.form?.mix?.drugs.reduce(
                (a, { detail }) => a + (detail?.price || 0),
                0,
              )
            : this.form?.nonMix?.drug?.price) || 0
        );
      },
    },
  },
  methods: {
    ...mapMutations(['clearState']),
    ...mapActions(['resolveGetDrugs']),
    async saveDrug() {
      let arrValidation = [];
      for (
        let i = 0;
        i < this.refs?.ref?.mix?.$refs?.mixIngredients?.$refs?.formDrugs.length;
        i++
      ) {
        arrValidation.push(
          this.refs?.ref?.mix?.$refs?.mixIngredients?.$refs?.formDrugs[
            i
          ].validate(),
        );
      }
      try {
        this.loading = true;
        if (
          (this.refs?.ref?.mix?.$refs?.form?.validate() &&
            !arrValidation.some(val => val === false)) ||
          this.refs?.ref?.nonmix?.$refs?.form?.validate()
        ) {
          if (
            this.form.type &&
            !this.form.mix.drugs.every(item => item.detail)
          ) {
            Swal.fire('Terdapat obat racik yang belum diisi', '', 'warning');
            return;
          }
          let mix, nonmix;
          if (this.form.type) {
            mix = {
              _id: this.form.mix._id,
              old_name: this.form.mix.old_name_mix_recipe,
              isNew: !this.form.isEdit,
              name: this.form.mix.name,
              isOdd: this.form.schedule.isOdd,
              quantity: parseInt(this.form.mix.quantity),
              isOrdered: true,
              requested_quantity: parseInt(this.form.mix.amount_requested),
              packaging: this.form.mix.packaging,
              general_rule: this.form.mix.general_rule,
              route: this.form.mix.route,
              description: this.form.mix.description,
              usage: `${this.form.mix.usage.day} x ${this.form.mix.usage.usage} ${this.form.mix.usage.package}`,
              price: this.form.isEdit
                ? this.form.mix.drugs.reduce(
                    (a, { detail }) => a + detail.price,
                    0,
                  )
                : 0,
              date: moment(new Date())
                .locale('id')
                .format(),
              staff: this.$store.getters.userLoggedIn.id,
              time_delivery: this.form.schedule.schedule,
              recipe_number: this.drugs.total + 1,
              change_note: this.form.schedule.confirmationReason,
              is_extra_drugs: this.form.mix.is_extra_drugs,
              special_instruction: this.form.mix.special_instruction,
              staff_approve: this.form.isEdit
                ? this.$store.getters.userLoggedIn.name
                : undefined,
              drugs: this.form.mix.drugs.map(drug => {
                return {
                  id_drugs: drug.detail.id,
                  preparation: drug.detail.preparation,
                  name: drug.detail.text,
                  dosage: drug.detail.strength,
                  dosage_needed: drug.dosage_needed,
                  unit_dosage: drug.detail.strength_unit,
                  price: drug.detail.pricePerItem,
                  expiry: drug.detail.expiry,
                  packaging: drug.detail.packaging,
                  id_stock: drug.detail.id_stock,
                  availability_stock: drug.detail.stock,
                  requested_quantity: drug.amount_requested,
                  quantity: drug.amount_given,
                };
              }),
            };
          } else {
            nonmix = {
              old_id_drug: this.form.nonMix.old_id_drug,
              old_name: this.form.nonMix.old_name_recipe,
              old_dosage: this.form.nonMix.old_dosage,
              old_unit_dosage: this.form.nonMix.old_unit_dosage,
              _id: this.form.nonMix._id,
              id_drugs: this.form.nonMix.drug.id,
              id_stock: this.form.nonMix.drug.id_stock,
              isOdd: this.form.schedule.isOdd,
              availability_stock: this.form.nonMix.drug.stock,
              preparation: this.form.nonMix.drug.preparation || '-',
              dosage: this.form.nonMix.drug.strength,
              unit_dosage: this.form.nonMix.drug.strength_unit,
              quantity: parseInt(this.form.nonMix.amount_given),
              requested_quantity: parseInt(this.form.nonMix.amount_requested),
              packaging: this.form.nonMix.drug.packaging || '-',
              expiry: this.form.nonMix.drug.expiry,
              description: this.form.nonMix.description,
              usage_type: !this.form.nonMix.radio_usage,
              isOrdered: true,
              usage: !this.form.nonMix.radio_usage
                ? `${this.form.nonMix.usage.dropdownInput.day} x ${this.form.nonMix.usage.dropdownInput.usage} ${this.form.nonMix.usage.dropdownInput.package}`
                : `${this.form.nonMix.usage.manualInput.usage} ${this.form.nonMix.usage.manualInput.package}`,
              name: this.form.nonMix.drug.name,
              price: this.form.nonMix.drug.pricePerItem,
              general_rule: this.form.nonMix.general_rule,
              date: moment(new Date())
                .locale('id')
                .format(),
              staff: this.$store.getters.userLoggedIn.id,
              change_note: this.form.schedule.confirmationReason,
              is_extra_drugs: this.form.nonMix.is_extra_drugs,
              special_instruction: this.form.nonMix.special_instruction,
              staff_approve: this.form.isEdit
                ? this.$store.getters.userLoggedIn.name
                : undefined,
              route: this.form.nonMix.route,
              time_delivery: this.form.schedule.schedule,
              recipe_number: this.drugs.total + 1,
              isNew: !this.form.isEdit,
            };
          }
          const payload = {
            recipe: !this.form.type ? nonmix : undefined,
            mix_recipe: this.form.type ? mix : undefined,
            calculation_type: this.form.type
              ? this.$store.getters.userLoggedIn.config
                  .pharmacy_mix_recipe_input
              : undefined,
            timestamps: !this.form.isEdit
              ? {
                  created_by: this.$store.getters.userLoggedIn.id,
                }
              : undefined,
          };
          await putPharmacyDrug(
            {
              params: {
                type: this.pharmacyType,
                isTakeHome: this.form.isEdit ? this.isTakeHome : undefined,
              },
            },
            this.drugs.id,
            payload,
          );

          // // Reset form validation
          this.refs?.ref?.mix?.$refs?.form?.resetValidation();
          this.refs?.ref?.nonmix?.$refs?.form?.resetValidation();

          // // Refresh drug list
          this.resolveGetDrugs('requested');

          // // Show success alert
          Swal.fire(
            `Berhasil ${this.form.isEdit ? 'mengubah' : 'menambahkan'}  obat ${
              this.form.isEdit ? '' : 'baru'
            }`,
            '',
            'success',
          );

          // // Clear form
          this.clearState('form');
          this.form.isEdit = false;
        }
      } catch {
        Swal.fire(
          `Terjadi kesalahan, gagal ${
            this.form.isEdit ? 'mengubah obat' : 'menambahkan obat baru'
          }`,
          '',
          'error',
        );
      } finally {
        this.loading = false;
      }
    },
    insertString(originalString, newString, index) {
      return (
        originalString.substr(0, index) +
        newString +
        originalString.substr(index)
      );
    },
    formatTime(timeString, index) {
      if (timeString.length > 5) {
        this.form.schedule.schedule[index].time = timeString.slice(0, 5);
        return;
      }
      let time = timeString.replace(/\D/g, '').substr(0, 4);
      let size = time.length;

      if (size > 2) time = this.insertString(time, ':', 2);

      this.form.schedule.schedule[index].time = time;
    },
    checkTimeFormat(time) {
      if (time.length !== 5) return false;
      const timeInput = moment(time, 'HH:mm');
      return timeInput.isValid();
    },
  },
};
</script>

<style scoped lang="scss">
$grid-amount: 8;
* {
  color: #101010;
  .schedule-container-grid {
    display: grid;
    margin-bottom: 0.7vw;
    column-gap: 2vw;
    grid-template-areas:
      'area-0 area-1'
      'area-2 area-3'
      'area-4 area-5'
      'area-6 area-7';

    @for $i from 0 through $grid-amount {
      .area-#{$i} {
        grid-area: area-#{$i};
      }
    }

    .schedule-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  .drug-price {
    display: flex;
    padding: 0.5vw;
    margin-bottom: 0.5vw;
    justify-content: space-between;
    background-color: #f0f9ff;
    border-radius: 4px;
    border: 0.5px solid #bdbdbd;
  }
}
</style>
