const { version } = require('../package.json');
import pklogo from '@/assets/image/PKLogo.png'; // PK logo
import moyudanLogo from '@/assets/image/moyudanLogo.png'; // Moyudan logo
import bhLogo from '@/assets/image/bhlogo.png'; // Bangun Husada logo
import jmcLogo from '@/assets/image/jmcLogo.png'; // Jogja Medical Center logo
import neuroviLogo from '@/assets/image/logoFix.js'; // Neurovi logo
import bpjsLogo from '@/assets/image/bpjs.png'; // BPJS logo
import sariAsihLogo from '@/assets/image/sariasihlogo.png'; // BPJS logo

import letterHeadPermataKeluarga from '@/assets/image/kopsurat_permata_keluarga.png';
import letterHeadBangunHusada from '@/assets/image/kopsurat_bangun_husada.png';
import letterHeadNeurovi from '@/assets/image/kopsurat_neurovi_default.png';
import letterHeadMoyudan from '@/assets/image/kopsurat_moyudan.png';
import letterHeadJMC from '@/assets/image/kopsurat_jmc_header.png';
import letterFootJMC from '@/assets/image/kopsurat_jmc_footer.png';
import letterHeadSariAsih from '@/assets/image/kopsurat_sari_asih.png';

// small letter head
import letterHeadNeuroviSm from '@/assets/image/kopsurat_neurovi_default_sm.png';
import letterPermataKeluargaSm from '@/assets/image/kopsurat_permata_keluarga_sm.png';
import letterHeadMoyudanSm from '@/assets/image/kopsurat_moyudan_sm.png';
import letterHeadJmcSm from '@/assets/image/kopsurat_jmc_sm.png';
import letterHeadBangunHusadaSm from '@/assets/image/kopsurat_bangun_husada_sm.png';

const constant = {
  client: [
    {
      name: 'klinik permata keluarga',
      regionalDeputy: 'KEDEPUTIAN WILAYAH VI',
      fktpNumber: '0178B514',
      letterHead: {
        src: letterHeadPermataKeluarga,
        width: 6.18 * 1.3,
        height: 1.28 * 1.3,
      },
      smallLetterHead: {
        src: letterPermataKeluargaSm,
        width: 4.4,
        height: 0.95,
      },
      leftLogo: pklogo,
      leftLogoExt: 'PNG',
      rightLogo: bpjsLogo,
      rightLogoExt: 'PNG',
      landscape: {
        image: {
          left: {
            x: 0.8,
            y: 0.3,
            height: 0.9,
            width: 1.6,
          },
          right: {
            x: 9.8,
            y: 0.3,
            height: 0.95,
            width: 0.95,
          },
        },
        title: {
          offset: 0,
          offsetData: 7,
        },
      },
      image: {
        left: {
          x: 0.8,
          y: 0.6,
          height: 0.9,
          width: 1.67,
        },
        right: {
          x: 6.5,
          y: 0.7,
          height: 0.7,
          width: 0.7,
        },
      },
      title: {
        offset: 1,
        offsetData: 2.43,
      },
      cppt: {
        image: {
          width: 1,
          height: 0.5,
          x: 0.3,
          y: 7.6,
        },
        text: {
          x: 1.4,
          text: 'KLINIK PERMATA KELUARGA',
        },
      },
    },
    {
      name: 'klinik bangun husada',
      regionalDeputy: 'KEDEPUTIAN WILAYAH VI',
      fktpNumber: '0179B521',
      letterHead: {
        src: letterHeadBangunHusada,
        width: 6.18 * 1.3,
        height: 1.28 * 1.3,
      },
      smallLetterHead: {
        src: letterHeadBangunHusadaSm,
        width: 4.4,
        height: 0.95,
      },
      leftLogo: bhLogo,
      leftLogoExt: 'PNG',
      rightLogo: '',
      rightLogoExt: 'JPEG',
      landscape: {
        image: {
          left: {
            x: 0.8,
            y: 0.3,
            height: 0.8,
            width: 1.5,
          },
          right: {
            x: 9.8,
            y: 0.3,
            height: 0.95,
            width: 0.95,
          },
        },
        title: {
          offset: 0,
          offsetData: 7,
        },
      },
      image: {
        left: {
          x: 1,
          y: 0.7,
          height: 0.6,
          width: 1,
        },
        right: {
          x: 6.5,
          y: 0.7,
          height: 0.7,
          width: 0.7,
        },
      },
      title: {
        offset: 0.62,
        offsetData: 2.33,
      },
      cppt: {
        image: {
          width: 0.8,
          height: 0.5,
          x: 0.3,
          y: 7.6,
        },
        text: {
          x: 1.2,
          text: 'KLINIK PRATAMA BANGUN HUSADA',
        },
      },
    },
    {
      name: 'jogja medical center',
      regionalDeputy: 'KEDEPUTIAN WILAYAH VI',
      fktpNumber: '0179B521',
      letterHead: { src: letterHeadJMC, width: 10, height: 1.5 },
      letterFoot: { src: letterFootJMC, width: 8.27, height: 6 },
      smallLetterHead: {
        src: letterHeadJmcSm,
        width: 4.4,
        height: 0.95,
      },
      leftLogo: jmcLogo,
      leftLogoExt: 'PNG',
      rightLogo: '',
      rightLogoExt: 'JPEG',
      landscape: {
        image: {
          left: {
            x: 0.8,
            y: 0.3,
            height: 0.8,
            width: 1.7,
          },
          right: {
            x: 9.8,
            y: 0.3,
            height: 0.95,
            width: 0.95,
          },
        },
        title: {
          offset: 0,
          offsetData: 7,
        },
      },
      image: {
        left: {
          x: 1,
          y: 0.68,
          height: 0.59,
          width: 1.45,
        },
        right: {
          x: 6.5,
          y: 0.7,
          height: 0.7,
          width: 0.7,
        },
      },
      title: {
        offset: 1.1,
        offsetData: 3.2,
      },
      cppt: {
        image: {
          width: 1.2,
          height: 0.5,
          x: 0.3,
          y: 7.6,
        },
        text: {
          x: 1.6,
          text: 'JOGJA MEDICAL CENTER',
        },
      },
    },
    {
      name: 'klinik aisyiyah moyudan',
      regionalDeputy: 'KEDEPUTIAN WILAYAH VI',
      fktpNumber: '0178B514',
      letterHead: {
        src: letterHeadMoyudan,
        width: 6.18 * 1.3,
        height: 1.28 * 1.3,
      },
      smallLetterHead: {
        src: letterHeadMoyudanSm,
        width: 4.4,
        height: 0.95,
      },
      leftLogo: moyudanLogo,
      leftLogoExt: 'PNG',
      rightLogo: bpjsLogo,
      rightLogoExt: 'PNG',
      landscape: {
        image: {
          left: {
            x: 1,
            y: 0.3,
            height: 0.9,
            width: 1.1,
          },
          right: {
            x: 9.8,
            y: 0.3,
            height: 0.95,
            width: 0.95,
          },
        },
        title: {
          offset: 0,
          offsetData: 7,
        },
      },
      image: {
        left: {
          x: 0.8,
          y: 0.6,
          height: 0.9,
          width: 1.67,
        },
        right: {
          x: 6.5,
          y: 0.7,
          height: 0.7,
          width: 0.7,
        },
      },
      title: {
        offset: 1,
        offsetData: 2.43,
      },
      cppt: {
        image: {
          width: 0.5,
          height: 0.5,
          x: 0.3,
          y: 7.6,
        },
        text: {
          x: 1,
          text: 'KLINIK PRATAMA `AISYIYAH MOYUDAN',
        },
      },
    },
    {
      name: 'klinik sari asih',
      regionalDeputy: 'KEDEPUTIAN WILAYAH VI',
      fktpNumber: '',
      letterHead: {
        src: letterHeadSariAsih,
        width: 6.18 * 1.3,
        height: 1.28 * 1.3,
      },
      leftLogo: sariAsihLogo,
      leftLogoExt: 'PNG',
      rightLogo: bpjsLogo,
      rightLogoExt: 'PNG',
      landscape: {
        image: {
          left: {
            x: 1,
            y: 0.3,
            height: 0.9,
            width: 1.1,
          },
          right: {
            x: 9.8,
            y: 0.3,
            height: 0.95,
            width: 0.95,
          },
        },
        title: {
          offset: 0,
          offsetData: 7,
        },
      },
      image: {
        left: {
          x: 0.8,
          y: 0.6,
          height: 0.9,
          width: 1.67,
        },
        right: {
          x: 6.5,
          y: 0.7,
          height: 0.7,
          width: 0.7,
        },
      },
      title: {
        offset: 1,
        offsetData: 2.43,
      },
      cppt: {
        image: {
          width: 0.5,
          height: 0.5,
          x: 0.3,
          y: 7.6,
        },
        text: {
          x: 0.85,
          text: 'KLINIK PRATAMA RAWAT JALAN SARI ASIH',
        },
      },
    },
    {
      name: 'neurovi clinic medical system',
      regionalDeputy: 'KEDEPUTIAN WILAYAH VI',
      fktpNumber: '0179B521',
      letterHead: {
        src: letterHeadNeurovi,
        width: 6.18 * 1.3,
        height: 1.28 * 1.3,
      },
      smallLetterHead: {
        src: letterHeadNeuroviSm,
        width: 4.4,
        height: 0.95,
      },
      leftLogo: neuroviLogo,
      leftLogoExt: 'PNG',
      rightLogo: bpjsLogo,
      rightLogoExt: 'PNG',
      image: {
        left: {
          x: 1,
          y: 0.9,
          height: 0.35,
          width: 1.35,
        },
        right: {
          x: 6.5,
          y: 0.7,
          height: 0.7,
          width: 0.7,
        },
      },
      title: {
        offset: 1.14,
        offsetData: 2.8,
      },
      landscape: {
        image: {
          left: {
            x: 0.8,
            y: 0.6,
            height: 0.42,
            width: 1.5,
          },
          right: {
            x: 9.8,
            y: 0.3,
            height: 0.95,
            width: 0.95,
          },
        },
        title: {
          offset: 0,
          offsetData: 7,
        },
      },
      cppt: {
        image: {
          width: 1.2,
          height: 0.3,
          x: 0.3,
          y: 7.7,
        },
        text: {
          x: 1.7,
          text: 'NEUROVI CLINIC MEDICAL SYSTEM',
        },
      },
    },
  ],
  apiUrl: process.env.VUE_APP_API_URL,
  version,
  passwordAuth: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%&?"_'-]{8,}$/,
  menus: [
    {
      title: 'Master Data',
      icon: 'mdi-database',
      slug: 'masterdata',
      children: [
        {
          title: 'Barang Farmasi',
          route: '/masterdata/drugs',
          code: '1.1',
        },
        {
          title: 'Barang Rumah Tangga',
          route: '/masterdata/goods',
          code: '1.2',
        },
        {
          title: 'Staf',
          route: '/masterdata/staff',
          code: '1.3',
        },
        {
          title: 'Diagnosa',
          route: '/masterdata/diagnosa',
          code: '1.4',
        },
        {
          title: 'Prosedur',
          route: '/masterdata/prosedur',
          code: '1.5',
        },
        {
          title: 'Unit',
          route: '/masterdata/unit',
          code: '1.6',
        },
        {
          title: 'Layanan',
          route: '/masterdata/layanan',
          code: '1.7',
        },
        {
          title: 'Supplier',
          route: '/masterdata/supplier',
          code: '1.8',
        },
        {
          title: 'Bed',
          route: '/masterdata/bed',
          code: '1.9',
        },
        {
          title: 'Laboratorium',
          route: '/masterdata/laboratory',
          code: '1.10',
        },
      ],
    },
    {
      title: 'EMR',
      icon: 'mdi-note-plus',
      route: '/emr',
      slug: 'emr',
      children: [],
      code: '4',
    },
    {
      title: 'Pendaftaran',
      icon: 'mdi-file-document-edit',
      slug: 'registration',
      children: [
        {
          title: 'Rawat Jalan',
          route: '/pendaftaran/rawat-jalan',
          code: '2.1',
          hidden: false,
        },
        {
          title: 'Laboratorium',
          route: '/pendaftaran/laboratorium',
          code: '2.7',
          hidden: false,
        },
        {
          title: 'IGD',
          route: '/pendaftaran/igd',
          code: '2.2',
          hidden: false,
        },
        {
          title: 'Rawat Inap',
          route: '/pendaftaran/rawat-inap',
          code: '2.3',
          hidden: false,
        },
        {
          title: 'Ketersediaan Bed',
          route: '/pendaftaran/ketersediaan-bed',
          code: '2.4',
          hidden: false,
        },
        {
          title: 'Jadwal Dokter',
          route: '/pendaftaran/jadwal-dokter',
          code: '2.5',
          hidden: false,
        },
      ],
    },
    {
      title: 'Pelayanan',
      icon: 'mdi-hand-heart',
      slug: 'service',
      children: [
        {
          title: 'Rawat Inap',
          route: '/pelayanan/rawat-inap',
          code: '3.5',
        },
        {
          title: 'Klinik IGD',
          route: '/pelayanan/klinik-igd',
          code: '3.4',
        },
        {
          title: 'Poli Umum',
          route: '/pelayanan/poli-umum',
          code: '3.1',
        },
        {
          title: 'Poli Gigi',
          route: '/pelayanan/poli-gigi',
          code: '3.3',
        },
        {
          title: 'Poli KIA',
          route: '/pelayanan/poli-KIA',
          code: '3.2',
        },
        {
          title: 'Poli Fisioterapi',
          route: '/pelayanan/poli-fisioterapi',
          code: '3.7',
        },
        {
          title: 'Poli Imunisasi',
          route: '/pelayanan/poli-imunisasi',
          code: '3.6',
        },
        {
          title: 'Poli KB',
          route: '/pelayanan/poli-kb',
          code: '3.8',
        },
      ],
    },
    {
      title: 'Keuangan',
      icon: 'mdi-cash',
      slug: 'billing',
      children: [
        {
          title: 'Pembayaran',
          route: '/keuangan/billing',
          code: '10.1',
        },
        {
          title: 'Keuangan',
          route: '/keuangan/finance',
          code: '10.2',
        },
        {
          title: 'Laporan Keuangan',
          route: '/keuangan/laporan-keuangan',
          code: '10.4',
        },
        {
          title: 'Akuntansi',
          route: '/keuangan/akuntansi',
          code: '10.3',
        },
      ],
    },
    {
      title: 'Inventaris',
      icon: 'mdi-package-down',
      slug: 'inventory',
      children: [
        {
          title: 'Pemesanan Barang',
          route: '/inventaris/pemesanan',
          rule: 'isPharmacyWarehouse',
          code: '5.1',
        },
        {
          title: 'Penerimaan Barang',
          route: '/inventaris/penerimaan',
          rule: 'isWarehouse',
          code: '5.2',
        },
        {
          title: 'Informasi Stok',
          route: '/inventaris/stok',
          code: '5.3',
        },
        {
          title: 'Permintaan Barang Gudang',
          route: '/inventaris/permintaan-gudang',
          hidden: false,
          code: '5.5',
        },
        {
          title: 'Mutasi Barang',
          route: '/inventaris/mutasi',
          hidden: true,
        },
        {
          title: 'Stok Opname',
          route: '/inventaris/stok-opname',
          rule: 'isWarehouse',
          code: '5.6',
        },
      ],
    },
    {
      title: 'Farmasi',
      icon: 'mdi-pill',
      slug: 'pharmacy',
      code: '6',
      children: [
        {
          title: 'Rawat Inap',
          route: '/farmasi/rawat-inap',
          code: '6.1',
        },
        {
          title: 'Rawat Jalan',
          route: '/farmasi/rawat-jalan',
          code: '6.2',
        },
        {
          title: 'IGD',
          route: '/farmasi/igd',
          code: '6.3',
        },
      ],
    },
    {
      title: 'Laboratorium',
      icon: 'mdi-flask-empty',
      slug: 'laboratorium',
      children: [
        {
          title: 'Permintaan Pemeriksaan ',
          route: '/laboratorium/permintaan-pemeriksaan-lab',
          code: '7',
        },
      ],
    },
    {
      title: 'Reporting',
      icon: 'mdi-file-document-multiple',
      slug: 'reporting',
      children: [
        {
          title: 'Laporan Pelayanan',
          route: '/reporting/laporan-pelayanan',
          code: '9.1',
        },
        {
          title: 'Laporan Morbiditas',
          route: '/reporting/laporan-morbiditas',
          code: '9.2',
        },
        {
          title: 'Laporan Mortalitas',
          route: '/reporting/laporan-mortalitas',
          code: '9.3',
        },
      ],
    },
    {
      title: 'Setting',
      icon: 'mdi-cog',
      slug: 'setting',
      children: [
        {
          title: 'Password PCare',
          route: '/setting/password-pcare',
          code: '11.1',
        },
      ],
    },
    {
      title: 'Bridging',
      icon: 'mdi-file-document-arrow-right',
      slug: 'bridging',
      children: [
        {
          title: 'SATUSEHAT',
          route: '/bridging/satusehat',
          code: '12.1',
        },
        {
          title: 'PCare',
          route: '/bridging/pcare',
          code: '12.2',
        },
      ],
    },
  ],
};

export default constant;
