<template>
  <section class="pt-5">
    <h4>INFORMASI UMUM</h4>
    <v-row>
      <v-col>
        <label>PPA</label>
        <v-text-field
          v-model="form.ppa.name"
          dense
          light
          readonly
          hide-details
          single-line
        />
        <label>No Bed</label>
        <v-text-field
          v-model="form.bed_number"
          dense
          light
          hide-details
          single-line
          readonly
        />
      </v-col>
      <v-col>
        <label>Tanggal & Jam</label>
        <div class="d-flex">
          <v-text-field
            v-model="form.date"
            class="pr-4"
            dense
            light
            hide-details
            single-line
            readonly
          />
          <v-text-field
            v-model="form.time"
            :readonly="isDetail"
            type="time"
            @keydown="preventDefaultBehaviour($event)"
            placeholder="00:00"
            class="pl-4"
            dense
            light
            hide-details
            single-line
          />
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations } = createNamespacedHelpers('CPPTStore');

export default {
  name: 'GeneralInformation',
  computed: {
    ...mapGetters(['getForm']),
    form: {
      get() {
        return this.getForm.generalInformation;
      },
    },
    isDetail: {
      get() {
        return this.getForm.isDetail;
      },
    },
    isEdit: {
      get() {
        return this.isDetail ? this.getForm.isEdit : true;
      },
    },
  },
  methods: {
    ...mapMutations(['setForm']),
    preventDefaultBehaviour(event) {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
  },
  mounted() {
    if (!this.isDetail) {
      this.setForm({
        generalInformation: {
          ...this.form,
          ppa: {
            name: this.$store.getters.userLoggedIn.name,
            id: this.$store.getters.userLoggedIn.id,
          },
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
section {
  text-align: start;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 2vw;

  label {
    font-weight: 500;
    font-size: 0.8vw;
  }

  .v-text-field {
    margin-bottom: 1vw;
  }

  h4 {
    font-weight: 400;
    color: #137bc0;
    margin-bottom: 0.7vw;
  }
}

/* Hide the time input's arrow icon (for some browsers) */
::v-deep
  .v-text-field__slot
  input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
