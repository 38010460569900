import {
  getPatientPharmacy,
  getRecipeByIdAndCategory,
  putReloadRecipe,
  deleteRecipeDrug,
} from '@/fetchApi/Pharmacy';
import moment from 'moment';

const state = {
  menuSelected: 0,
  page: 0,
  drugs: [],
  tab: 1,
  isLoading: {
    pharmacy: false,
    recipe: false,
  },
  patient: {
    rmNumber: '',
    birthDate: '',
    assurance: '',
    name: '',
    unit: '',
    age: '',
    gender: '',
    idPharmacy: '',
    allergy: [],
    status: '',
  },
  recipe: {
    id: '',
    staff: '',
    list: [],
    total: 0,
  },
  form: {
    isEdit: false,
    snackbar: false,
    type: false,
    nonMix: {
      drug: '',
      preparation: '',
      dosage: '',
      unit_dosage: '',
      radio_usage: false,
      quantity: '',
      amount_requested: 0,
      packaging: '',
      description: '',
      general_rule: '',
      usage: {
        dropdownInput: {
          usage: '',
          day: '',
          package: '',
        },
        manualInput: {
          usage: '',
          day: '',
          package: '',
        },
      },
      price: '',
      route: '',
    },
    mix: {
      name: '',
      drugs: [
        {
          detail: '',
          amount_requested: '',
          amount_given: '',
          strength: '',
        },
      ],
      quantity: '',
      packaging: '',
      description: '',
      usage: {
        usage: '',
        day: '',
        package: '',
      },
      price: '',
      general_rule: '',
      route: '',
      amount_requested: 0,
    },
    schedule: {
      isOdd: false,
      confirmationReason: '',
      schedule: [
        {
          name: 'pagi',
          time: '05:30',
          isNextDay: false,
          menu: false,
          check: false,
        },
        {
          name: 'siang',
          time: '13:30',
          isNextDay: false,
          menu: false,
          check: false,
        },
        {
          name: 'sore',
          time: '17:30',
          isNextDay: false,
          menu: false,
          check: false,
        },
        {
          name: 'malam',
          time: '21:30',
          isNextDay: false,
          menu: false,
          check: false,
        },
        {
          name: 'pagi',
          time: '05:30',
          isNextDay: true,
          menu: false,
          check: false,
        },
        {
          name: 'siang',
          time: '13:30',
          isNextDay: true,
          menu: false,
          check: false,
        },
        {
          name: 'sore',
          time: '17:30',
          isNextDay: true,
          menu: false,
          check: false,
        },
        {
          name: 'malam',
          time: '21:30',
          isNextDay: true,
          menu: false,
          check: false,
        },
      ],
    },
  },
  drugHistory: {
    mix_recipe: [],
    recipe: [],
    timestamps: '',
  },
  drugReview: {
    items: {
      administrative: {
        name: true,
        age: true,
        doctor: true,
        date: true,
      },
      clinical: {
        indication: true,
        dosage: true,
        time: true,
        duplication: false,
        allergy: false,
        rotd: false,
      },
      drugs_review: {
        patient: true,
        drugs: true,
        dosage: true,
        administer: true,
        time: true,
        expiry: true,
      },
      pharmaceuticals: {
        name: true,
        strength: true,
        dosage: true,
        quantity: true,
        rule: true,
      },
      id_staff: '',
      note: '',
      type: 'requested',
      recipeVerifier: '',
      drugsVerifier: '',
    },
    isSaved: false,
  },
  medicalTools: [],
  totalDrugPrice: 0,
};

const getters = {
  getMenuSelected: state => state.menuSelected,
  getTab: state => state.tab,
  getMedTools: state => state.medicalTools,
  getDrugReview: state => state.drugReview,
  getDrugHistory: state => state.drugHistory,
  getForm: state => state.form,
  getDrugs: state => state.drugs,
  getPatientData: state => state.patient,
  getRecipe: state => state.recipe,
  getLoading: state => state.isLoading,
  getPage: state => state.page,
  getTotalPrice: state => {
    const prices = state.recipe.list.map(drug => drug.price);
    return prices.reduce((a, b) => a + b, 0);
  },
};

const actions = {
  async resolveGetPharmacyPatientData({ commit, state }, id) {
    try {
      commit('setLoading', { label: 'pharmacy', value: true });
      const response = await getPatientPharmacy(id);
      const { data } = response.data;
      const {
        id_emr: social,
        id_registration: registratiion,
        id_recipe,
      } = data;
      // Set patient data
      commit('setPatient', {
        rmNumber: social.medical_record_number,
        birthDate: moment(social.social[0]?.birth?.date)
          .locale('id')
          .format('L'),
        assurance: social.social[0]?.assurance?.type?.toUpperCase(),
        name: social.social[0]?.name,
        unit: registratiion.id_unit?.name,
        age: social.social[0]?.age,
        gender: social.social[0]?.gender ? 'L' : 'P',
        idPharmacy: data._id,
        status: data.recipe.patient_status,
        allergy: social.checkup.filter(
          i => i.subjective?.allergy_history?.length,
        )[0]?.subjective?.allergy_history,
      });

      // Set recipe data
      commit('setRecipe', {
        ...state.recipe,
        id: id_recipe,
      });

      // Set drug history
      commit('setDrugHistory', {
        mix_recipe: data.history.flatMap(item => item.mix_recipe) || [],
        recipe: data.history.flatMap(item => item.recipe) || [],
        timestamps:
          data.history.flatMap(item => item.timestamps.created_at) || '',
      });

      commit('setDrugReview', {
        label: 'items',
        value: {
          ...state.drugReview.items,
          id_staff: data.status[0].id_staff,
          type: data.status[0].type,
        },
      });
      // Set drug review
      if (
        data.status[0].type === 'handover' ||
        data.status[0].type === 'finish'
      ) {
        commit('setDrugReview', {
          label: 'items',
          value: {
            administrative: data.administrative,
            clinical: data.clinical,
            drugs_review:
              data.drugs_review.administer === null
                ? Object.fromEntries(
                    Object.keys(data.drugs_review).map(key => [key, true]),
                  )
                : data.drugs_review,
            pharmaceuticals: data.pharmaceutical,
            id_staff: data.status[0].id_staff,
            recipeVerifier: data.recipe_verifier
              ? data.recipe_verifier.detail[0].name
              : null,
            drugsVerifier: data.drugs_verifier
              ? data.drugs_verifier.detail[0].name
              : null,
            type: data.status[0].type,
            note: data.description,
          },
        });
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit('setLoading', { label: 'pharmacy', value: false });
    }
  },
  async resolveGetDrugs({ commit, state }, category) {
    try {
      commit('setLoading', { label: 'recipe', value: true });
      const params = {
        category,
        id: state.recipe.id,
      };
      const response = await getRecipeByIdAndCategory(params);
      const {
        medical_tools,
        mix_recipe,
        recipe,
        timestamps,
      } = response.data.data;

      // set staff ordered recipe
      commit('setRecipe', {
        ...state.recipe,
        staff: timestamps.created_by.name,
      });

      const mix = mix_recipe?.map(mix => {
        return {
          id: mix._id,
          name: mix.name,
          drugs: mix.drugs,
          status: mix.isDelivered,
          usage: mix.usage,
          isRacik: true,
          isApproved: mix.isApproved,
          quantity: mix.quantity,
          general_rule: mix.general_rule,
          isOdd: mix.isOdd || false,
          packaging: mix.packaging,
          description: mix.description,
          date: moment(mix.date)
            .locale('id')
            .format('l'),
          staff: mix.staff,
          expanded: false,
          change_note: mix.change_note,
          price: mix.price,
          pricePerItem: mix.pricePerItem,
          requested_quantity: mix.requested_quantity || 0,
          time_delivery: mix.time_delivery,
          route: mix.route,
          recipe_number: mix.recipe_number,
          special_instruction: mix.special_instruction,
          is_extra_drugs: mix.is_extra_drugs,
          isActive: false,
          isAvailable: mix.drugs.every(drug => drug.available_stock === true),
        };
      });

      const nonMix = recipe?.map(nonMix => {
        return {
          id: nonMix._id,
          id_stock: nonMix.id_stock,
          isApproved: nonMix.isApproved,
          drugs: nonMix.id_drugs,
          preparation: nonMix.preparation,
          dosage: nonMix.dosage,
          unit_dosage: nonMix.unit_dosage,
          quantity: nonMix.quantity,
          packaging: nonMix.packaging,
          status: nonMix.isDelivered,
          general_rule: nonMix.general_rule,
          isOdd: nonMix.isOdd || false,
          description: nonMix.description,
          usage: nonMix.usage,
          usage_type: nonMix.usage_type,
          name: nonMix.id_drugs.name,
          drug_name: nonMix.name,
          expiry: nonMix.expiry || '-',
          date: moment(nonMix.date)
            .locale('id')
            .format('l'),
          staff: nonMix.staff,
          isRacik: false,
          expanded: false,
          change_note: nonMix.change_note,
          price: nonMix.price,
          pricePerItem: nonMix.pricePerItem,
          time_delivery: nonMix.time_delivery,
          route: nonMix.route,
          special_instruction: nonMix.special_instruction,
          is_extra_drugs: nonMix.is_extra_drugs,
          requested_quantity: nonMix.requested_quantity,
          recipe_number: nonMix.recipe_number,
          isActive: false,
          isAvailable: nonMix.available_stock,
          remainingStock: nonMix.availability_stock,
        };
      });
      commit('setMedTool', {
        id: state.recipe.id,
        item: medical_tools?.map((medtool, index) => {
          return {
            ...medtool,
            name: medtool.id_drugs.name,
            status: medtool.isDelivered,
            id: medtool.id_drugs._id,
            no: index + 1,
            amountDemanded: medtool.requested_quantity,
            amountGiven: medtool.quantity
              ? medtool.quantity
              : medtool.requested_quantity,
            unit: medtool.packaging,
            isMenu: false,
            isActive: false,
          };
        }),
      });

      commit('setRecipe', {
        ...state.recipe,
        list: mix
          .concat(nonMix)
          .sort((a, b) => a.recipe_number - b.recipe_number)
          .map((v, i) => {
            return { ...v, idx: i };
          }),
        total:
          (mix?.length || 0) +
          (nonMix?.length || 0) +
          (medical_tools?.length || 0),
      });
    } catch (error) {
      commit('setRecipe', {
        ...state.recipe,
        list: [],
        total: 0,
        staff: '',
      });
      throw new Error(error);
    } finally {
      commit('setLoading', { label: 'recipe', value: false });
    }
  },
  async resolveReloadRecipe({ state }) {
    try {
      await putReloadRecipe(state.recipe.id);
    } catch (error) {
      throw new Error(error);
    }
  },
  async resolveDeleteDrug({ state }, id) {
    try {
      await deleteRecipeDrug(id, state.recipe.id);
    } catch (error) {
      throw new Error(error);
    }
  },
};

const mutations = {
  clearState(state, label) {
    switch (label) {
      case 'form':
        state.page = 0;
        state.form = {
          ...state.form,
          type: false,
          nonMix: {
            drug: '',
            preparation: '',
            dosage: '',
            unit_dosage: '',
            quantity: '',
            radio_usage: false,
            amount_requested: 0,
            packaging: '',
            description: '',
            usage: {
              dropdownInput: {
                usage: '',
                day: '',
                package: '',
              },
              manualInput: {
                usage: '',
                day: '',
                package: '',
              },
            },
            price: '',
          },
          mix: {
            name: '',
            drugs: [
              {
                detail: '',
                amount_requested: '',
                amount_given: '',
                strength: '',
              },
            ],
            quantity: '',
            packaging: '',
            description: '',
            usage: {
              usage: '',
              day: '',
              package: '',
            },
            price: '',
            general_rule: '',
            route: '',
            amount_requested: 0,
          },
          schedule: {
            isOdd: false,
            confirmationReason: '',
            schedule: [
              { name: 'pagi', time: '05:30', isNextDay: false, menu: false },
              { name: 'siang', time: '13:30', isNextDay: false, menu: false },
              { name: 'sore', time: '17:30', isNextDay: false, menu: false },
              { name: 'malam', time: '21:30', isNextDay: false, menu: false },
              { name: 'pagi', time: '05:30', isNextDay: true, menu: false },
              { name: 'siang', time: '13:30', isNextDay: true, menu: false },
              { name: 'sore', time: '17:30', isNextDay: true, menu: false },
              { name: 'malam', time: '21:30', isNextDay: true, menu: false },
            ],
          },
        };
        break;
      case 'recipe':
        state.recipe = {
          id: '',
          staff: '',
          list: [],
          total: 0,
        };
        break;
      case 'patient':
        state.patient = {
          rmNumber: '',
          birthDate: '',
          assurance: '',
          name: '',
          unit: '',
          age: '',
          gender: '',
          idPharmacy: '',
          status: '',
          allergy: [],
        };
        break;
      case 'price':
        state.totalDrugPrice = 0;
        break;
      case 'history':
        state.drugHistory = {
          mix_recipe: [],
          recipe: [],
          timestamps: '',
        };
        break;
      case 'review':
        state.drugReview = {
          items: {
            administrative: {
              name: true,
              age: true,
              doctor: true,
              date: true,
            },
            clinical: {
              indication: true,
              dosage: true,
              time: true,
              duplication: false,
              allergy: false,
              rotd: false,
            },
            drugs_review: {
              patient: true,
              drugs: true,
              dosage: true,
              administer: true,
              time: true,
              expiry: true,
            },
            pharmaceuticals: {
              name: true,
              strength: true,
              dosage: true,
              quantity: true,
              rule: true,
            },
            id_staff: '',
            note: '',
            type: 'requested',
            recipeVerifier: '',
            drugsVerifier: '',
          },
          isSaved: false,
        };
        break;
      case 'menu':
        state.menuSelected = 0;
        break;
      case 'medtool':
        state.medicalTools = [];
        break;
    }
  },
  setPage(state, payload) {
    state.page = payload;
  },
  setMenuSelected(state, payload) {
    state.menuSelected = payload;
  },
  setDrugHistory(state, payload) {
    state.drugHistory = payload;
  },
  setDrugReview(state, payload) {
    const { label, value } = payload;
    state.drugReview[label] = value;
  },
  setTab(state, payload) {
    state.tab = payload;
  },
  setMedTool(state, payload) {
    state.medicalTools = payload || [];
  },
  setForm(state, payload) {
    const { label, value } = payload;
    state.form[label] = value;
  },
  setDrugs(state, payload) {
    state.drugs = payload;
  },
  setPatient(state, payload) {
    state.patient = payload;
  },
  setRecipe(state, payload) {
    state.recipe = payload;
  },
  setLoading(state, payload) {
    const { label, value } = payload;
    state.isLoading[label] = value;
  },
  updateMixDrugQuantity(state, { dosage, index }) {
    if (isNaN(index)) {
      const newArray = state.form?.mix?.drugs?.map(drug => {
        return {
          ...drug,
          amount_requested:
            ((drug?.strength || 0) * +state.form.mix.quantity) /
            (+drug.detail?.strength || 1),
          amount_given: Math.ceil(
            ((drug?.strength || 0) * +state.form.mix.quantity) /
              (+drug.detail?.strength || 1),
          ),
        };
      });
      state.form.mix.drugs = newArray;
    } else {
      const newObjectValue = {
        ...state.form.mix.drugs[index],
        amount_requested: +state.form?.mix?.drugs?.[index]?.detail?.strength
          ? (dosage * +state.form.mix.quantity) /
            +state.form?.mix?.drugs?.[index]?.detail?.strength
          : 0,
        amount_given: +state.form?.mix?.drugs?.[index]?.detail?.strength
          ? Math.ceil(
              (dosage * +state.form.mix.quantity) /
                +state.form?.mix?.drugs?.[index]?.detail?.strength,
            )
          : 0,
      };
      state.form.mix.drugs[index] = newObjectValue;
    }
    state.form.mix = {
      ...state.form.mix,
      drugs: state.form.mix.drugs,
    };
  },

  checkStock(state, isMix) {
    if (isMix) state.form.snackbar = true;
    if (state.form.nonMix.drug.quantity < state.form.nonMix.amount_requested) {
      state.form.snackbar = true;
    }
  },
  mapDrugDataToForm(state, index) {
    state.page = 0;
    const data = state.recipe.list[index];
    state.form.type = data.isRacik;
    if (data.isRacik) {
      state.form.mix = {
        old_name_mix_recipe: data.name,
        _id: data.id,
        name: data.name,
        recipe_number: data.recipe_number,
        drugs: data.drugs.map(i => {
          return {
            detail: {
              text: i.id_drugs.name,
              name: i.id_drugs.name,
              id: i.id_drugs._id,
              dosage_needed: i.dosage_needed,
              strength: i.dosage,
              strength_unit: i.unit_dosage,
              packaging: i.preparation,
              preparation: i.preparation,
              price: i.price,
              pricePerItem: i.pricePerItem,
              quantity: i.availability_stock,
              id_stock: i?.id_stock,
              available_stock: i?.available_stock,
            },
            id: i._id,
            strength: i.dosage_needed,
            dosage_needed: i.dosage_needed,
            strength_unit: i.unit_dosage,
            amount_requested: i.requested_quantity,
            amount_given: Math.ceil(i.quantity),
            packaging: i.preparation,
            preparation: i.preparation,
            price: i.price,
            pricePerItem: i.pricePerItem,
          };
        }),
        quantity: data.quantity,
        packaging: data.packaging,
        description: data.description,
        price: data.price,
        general_rule: data.general_rule,
        route: data.route,
        amount_requested: data.requested_quantity,
        is_extra_drugs: data.is_extra_drugs,
        special_instruction: data.special_instruction,
        usage: {
          day: data.usage.split(' ')[0],
          usage: data.usage.split(' ')[2],
          package: `${data.usage.split(' ')[3]} ${data.usage.split(' ')?.[4] ||
            ''}`,
        },
      };
    } else {
      state.form.nonMix = {
        ...state.form.nonMix,
        _id: data.id,
        drug: {
          id: data.drugs._id,
          id_stock: data?.id_stock,
          name: data.drug_name,
          text: data.drugs.name,
          strength_unit: data.unit_dosage,
          strength: data.dosage,
          price: data.price,
          pricePerItem: data.pricePerItem,
          packaging: data.packaging,
          preparation: data.preparation,
          quantity: data.remainingStock,
          stock: data.remainingStock,
        },
        old_id_drug: data.drugs._id,
        old_name_recipe: data.drug_name,
        old_dosage: data.dosage,
        old_unit_dosage: data.unit_dosage,
        id: data.id,
        dosage: data.dosage,
        unit_dosage: data.unit_dosage,
        amount_requested: data.requested_quantity,
        price: data.price,
        pricePerItem: data.pricePerItem,
        description: data.description,
        preparation: data.preparation,
        recipe_number: data.recipe_number,
        packaging: data.packaging,
        staff: data.staff,
        amount_given: data.quantity,
        route: data.route,
        general_rule: data.general_rule,
        is_extra_drugs: data.is_extra_drugs,
        special_instruction: data.special_instruction,
      };
      // Mapping nonmix drug usage
      if (data.usage_type) {
        state.form.nonMix.radio_usage = false;
        state.form.nonMix.usage.dropdownInput = {
          day: data.usage.split(' ')[0],
          usage: data.usage.split(' ')[2],
          package: `${data.usage.split(' ')[3]} ${data.usage.split(' ')[4] ||
            ''}`,
        };
      } else {
        state.form.nonMix.radio_usage = true;
        state.form.nonMix.usage.manualInput = {
          usage: data.usage.split(' ')[0],
          package: `${data.usage.split(' ')[1]} ${data.usage.split(' ')[2] ||
            ''}`,
        };
      }
    }
    // Mapping schedule
    state.form.schedule = {
      isOdd: data.isOdd,
      confirmationReason: data.change_note,
      schedule: data.time_delivery?.map((time, index) => {
        return {
          ...state.form.schedule.schedule[index],
          time: time.value || time.time,
          check: time.check,
          menu: time.menu,
        };
      }),
    };
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
