<template>
  <section class="pa-5 d-flex align-center justify-space-between">
    <div class="d-flex align-center" style="width: 40vw;">
      <img
        width="120vw"
        src="@/assets/image/NeuroviLogoSVG.svg"
        alt="clinic-logo"
        v-if="clinicName.toLowerCase() === 'neurovi clinic medical system'"
      />
      <img
        v-if="clinicName.toLowerCase() === 'klinik permata keluarga'"
        width="120vw"
        src="@/assets/image/PKLogo.png"
        alt="clinic-logo"
      />
      <img
        v-if="clinicName.toLowerCase() === 'klinik bangun husada'"
        width="120vw"
        src="@/assets/image/bhlogo.png"
        alt="clinic-logo"
      />
      <img
        v-if="clinicName.toLowerCase() === 'jogja medical center'"
        width="120vw"
        src="@/assets/image/jmcLogo.png"
        alt="clinic-logo"
      />
      <img
        v-if="clinicName.toLowerCase() === 'klinik aisyiyah moyudan'"
        width="80vw"
        src="@/assets/image/moyudanLogo.png"
        alt="clinic-logo"
      />
      <img
        v-if="clinicName.toLowerCase() === 'klinik sari asih'"
        width="80vw"
        src="@/assets/image/sariasihlogo.png"
        alt="clinic-logo"
      />
      <h2 class="text-capitalize ml-5 font-weight-regular">
        {{ clinicName }}
      </h2>
      <v-progress-circular
        indeterminate
        color="primary"
        class="ml-5"
        size="20"
        width="2"
        v-if="loading"
      ></v-progress-circular>
    </div>
    <div class="d-flex align-center">
      <img
        max-width="6vw"
        src="@/assets/image/NeuroviLogoSVG.svg"
        alt="clinic-logo"
      />
      <div class="time">
        <h2>
          {{ currentTime }}
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
const moment = require('moment-timezone');

export default {
  name: 'DashboardQueueHeader',
  props: {
    clinic: String,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTime: '',
    };
  },
  computed: {
    clinicName: {
      get() {
        return this.$store.getters.userLoggedIn.clinic.name;
      },
    },
  },
  mounted() {
    this.updateTime();
    this.startClock();
  },
  methods: {
    updateTime() {
      this.currentTime = moment().format('HH:mm');
    },
    startClock() {
      setInterval(this.updateTime, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.time {
  border: 2px solid $primary-color;
  color: $primary-color;
  border-radius: 7px;
  padding: 0.1vw 0.8vw;
  height: fit-content;
  margin-left: 1vw;
}
</style>
