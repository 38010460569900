<template>
  <v-card class="d-flex asessment-card">
    <history-sidebar v-if="!isEmr && !isIhs" />
    <v-container fluid>
      <header-assessment
        :data="headerData"
        pl="2vw"
        pr="2vw"
        title="Assesmen Poli Umum"
      />
      <v-card-text v-if="!isLoading" class="pb-0">
        <InputAssessment
          v-if="!emrData"
          @close-form="$emit('close-form')"
          :patient-data="patient"
          :is-emr="isEmr"
          :is-ihs="isIhs"
        />

        <InputAssessment
          v-else
          @close-form="$emit('close-form')"
          :patient-data="patient"
          :is-emr="isEmr"
          :emr-data="emrData"
          :is-ihs="isIhs"
        />
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
// components
import HistorySidebar from '../AssessmentComponents/HistorySidebar.vue';
import InputAssessment from './InputAssestment';
import HeaderAssessment from '../AssessmentComponents/HeaderAssessment.vue';
import formatMixin from '@/mixin/formatMixin';

// utils
const moment = require('moment');

// api
import { getSocialDataByRmNumber } from '@/fetchApi/patient';

export default {
  name: 'GeneralPolyAssestment',
  mixins: [formatMixin],
  components: {
    InputAssessment,
    HistorySidebar,
    HeaderAssessment,
  },
  data() {
    return {
      headerData: null,
      patient: null,
      isLoading: true,
    };
  },
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    idRegistration: {
      type: String,
      default: null,
    },
    rmNumber: {
      type: String,
      default: null,
    },
    isIhs: {
      type: Boolean,
      default: null,
    },
    isEmr: Boolean,
    emrData: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.getSocialData();
  },
  methods: {
    async getSocialData() {
      try {
        this.isLoading = true;
        const response = await getSocialDataByRmNumber(
          this.patientData?.rmNumber || this.rmNumber,
        );
        this.assignHeaderData(response.data.data);
        this.assignPatientDataForProps(response.data.data);
      } catch {
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },
    assignHeaderData(data) {
      this.headerData = [
        {
          label: 'No.RM',
          text: this.patientData?.rmNumber || this.rmNumber,
        },
        {
          label: 'Nama',
          text: `${data.name} (${data.gender ? 'L' : 'P'})`,
        },
        {
          label: 'Tanggal Lahir',
          text: `${moment(data.birth.date, 'YYYY-MM-DD').format(
            'DD/MM/YYYY',
          )} (${data.age} Tahun)`,
        },
        {
          label: 'Hambatan dalam Berkomunikasi',
          text: data.commmunication_obstacles || 'Tidak Ada',
        },
      ];
    },
    assignPatientDataForProps(data) {
      this.patient = {
        rmNumber: this.patientData?.rmNumber || this.rmNumber,
        meta: {
          name: data.name,
          birthDate: moment(data.birth.date, 'YYYY-MM-DD').format(
            'DD MMMM YYYY',
          ),
          // name: data.name,
          address: this.formatAddress(data.address),
          poly: this.patientData.meta.poly,
          age: data.age,
          gender: data.gender,
          guarantorType: data.assurance.type,
          guarantorNumber: data.assurance.number,
          // address: data.address,
          // poly: 'Poli Umum',
        },
        // rmNumber: this.patientData.rmNumber,
        id_registration:
          this.patientData?.id_registration || this.idRegistration,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.asessment-card {
  height: 100%;
}
</style>
